import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from "rxjs";
import {
  Finishing,
  PageSize,
  PagesPerSheet,
  PrintColor,
  PrintFileStatus,
  ProductType,
  Settings
} from "../models/models";
import { ShoppingCart } from "../models/cart.model";
import { environment } from "../../environments/environment";
import { SwiperOptions } from "swiper/types";
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../shared/app.service";
import { CartService } from "../shared/cart.service";
import { ProductService } from "../shared/product.service";
import {
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  PopoverController,
  ToastController
} from "@ionic/angular";
import { FileService } from "../shared/file.service";
import { UserService } from "../core/user.service";
import { SettingService } from "../shared/setting.service";
import { PrintProductStateService } from "../home/print-product-state.service";
import { GoogleDrivePickerService } from "../shared/services/googledrivepicker.service";
import { DropboxPickerService } from "../shared/services/dropbox-picker.service";
import { AcceptanceService } from "../shared/acceptance.service";
import { EncryptionService } from "../shared/encryption.service";
import { cloneDeep, isEqual, isNil } from "lodash";
import { map, takeUntil } from "rxjs/operators";
import { AlertModalComponent } from "../components/alert-modal/alert-modal.component";
import {
  PrintPermissionPolicyComponent
} from "../components/print-permission-policy/print-permission-policy.component";
import { ProductQtyComponent } from "../components/product-qty/product-qty.component";
import { Setting } from "../models/setting.model";
import AppBanners = Setting.AppBanners;
import {
  InfoProgramacionDidacticaModalComponent
} from "../components/info-programacion-didactica-modal/info-programacion-didactica-modal.component";

declare var google: any;
declare var Dropbox: any;

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss'],
})
export class HomeNewComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropRef: ElementRef;
  @ViewChild("qtyInput", { static: false }) qtyInput;
  @ViewChild('mainContent') mainContent: ElementRef;

  menuCollapsed = false;
  pdfToConvert;
  timeoutScrollId;
  mainContentWidth;
  widthObserver;
  settingsScrollHeight = 600;
  settingsWidth = 0;
  summaryScrollHeight = 600;
  summaryDisabled = true;
  private componetDestroyed = new Subject();
  productType = ProductType;
  isLoading = false;
  settingsChanges = false;
  itemIndex: 0;
  settingOptions = [
    "color",
    "pageSize",
    "paperWeight",
    "twoSided",
    "pagesPerSheet",
    "autoRotate",
    "pageOrientation",
    "pageTurn",
    "finishing",
    "grouped",
    "coverColor",
    "hardCoverFront",
    "hardCoverBack",
    "ringColor",
    "docColor",
    "skipFitToPage",
    "laminationType",
    "laminationWeight",
  ];
  settings;
  defaultSettings;
  files = [];
  printProduct: ShoppingCart.OrderItemUi;
  products;
  finishing = Finishing;
  finishingBinding;
  homeBanner$: Observable<string>;
  slider$: Observable<AppBanners>;
  settingsChanged$ = this.printProductStateService.settingsChanged$;
  coverTypeSubscription;
  isCoverIndeterminated$: Observable<boolean>;
  isCoverLaminatedIndeterminated$: Observable<boolean>;
  loading: HTMLIonLoadingElement;
  showingPresets = true;
  showingPrintSettings = true;
  showingFinishSettings = true;
  presetsHeight = null;
  printSettingsHeight = null;
  finishSettingsHeight = null;
  printPresets;
  selectedPreset = "Por defecto";
  dragOver = false;
  showSettings = false;
  showFolders = false;
  isProduction = environment.production;

  public slideOpts: SwiperOptions = {
    initialSlide: 0,
    grabCursor: true,
    pagination: false,
    speed: 700,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };
  isLargeScreen = false;
  protectedPrintModalOpened = false;

  @HostListener("window:resize")
  onWindowResize() {
    this.checkSizes()
  }

  public filesDrag: NgxFileDropEntry[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    public cartService: CartService,
    private productService: ProductService,
    public popoverController: PopoverController,
    private fileService: FileService,
    private userService: UserService,
    private menuCtrl: MenuController,
    private settingService: SettingService,
    private printProductStateService: PrintProductStateService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private dropboxPickerService: DropboxPickerService,
    private loadingController: LoadingController,
    private platform: Platform,
    private modalController: ModalController,
    private toastController: ToastController,
    private acceptanceService: AcceptanceService,
    private encryptS: EncryptionService,
    private cdRef: ChangeDetectorRef
  ) {
    this.defaultSettings = this.appService.getPrintSettings();
    this.settings = cloneDeep(this.defaultSettings);
    this.printPresets = this.appService.getPrintPresets();
    this.finishingBinding = productService.getFinishingData(
      this.finishing.BINDING
    );
  }

  onMenu() {
    this.menuCtrl.open();
  }

  async ngOnInit() {
    //FIXME JA
    // console.log("Files", this.files)
    // console.log("Files", this.printProduct?.product?.printingGroup?.files);
    this.checkSizes()
    //Setea si el checkbox de covercolor es indeterminado
    this.isCoverIndeterminated$ = this.settingsChanged$.pipe(
      map((value) => {
        const countChecked =
          this.printProduct.product.printingGroup.files.filter(
            (file) => file.coverColor
          ).length;
        this.printProduct.product.printingGroup.printSettings.coverColor =
          !!countChecked;
        const colorCountChecked =
          this.printProduct.product.printingGroup.files.filter(
            (file) => file.docColor
          ).length;
        this.printProduct.product.printingGroup.printSettings.docColor =
          !!colorCountChecked;
        this.setSettings(
          this.printProduct.product.printingGroup.printSettings,
          "coverColor"
        );
        return (
          countChecked > 0 &&
          this.printProduct.product.printingGroup.files.length !== countChecked
        );
      })
    );
    window.addEventListener('scroll', this.handleScroll, true);
    this.isCoverLaminatedIndeterminated$ = this.settingsChanged$.pipe(
      map((value) => {
        const countChecked =
          this.printProduct.product.printingGroup.files.filter(
            (file) => file.coverLaminated
          ).length;
        this.printProduct.product.printingGroup.printSettings.coverLaminated =
          !!countChecked;
        this.setSettings(
          this.printProduct.product.printingGroup.printSettings,
          "coverLaminated"
        );
        return false
        // return (
        //   countChecked > 0 &&
        //   this.printProduct.product.printingGroup.files.length !== countChecked
        // );
      })
    );

    // Suscripción a nuevo printGroup (caso de filestorage, url o al entrar)
    this.printProductStateService.printProduct$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((printProduct) => {
        this.printProduct = printProduct;
        // Esto puede traer problemas is estamos subiendo archivos
        const files = [];
        if (printProduct) {
          // initSettings debería normalizar y ajustar ordenes viejas a nuevo formato
          this.initSettings(
            this.printProduct.product.printingGroup.printSettings
          );
          printProduct.product.printingGroup.files.forEach((_file) => {
            files.push({
              file: null,
              isUploaded: true,
              progress: 0,
            });
          });
          this.files = files || [];
        }
      });

    this.settingsChanged$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(() => {
        this.handleCoverLaminatedConfigurations()
        if (this.printProduct) {
          this.printProduct.product.printingGroup.printSettings = cloneDeep(this.printProduct.product.printingGroup.printSettings);
        }
      });

    this.coverTypeSubscription = this.fileService.coverTypeChangedEvent.subscribe((i) => {
      setTimeout(() => {
        const coverType = this.printProduct.product.printingGroup.files[i].coverType;
        this.setDocOptionColor({
          action: "docOptions",
          setting: 'coverColor',
          value: coverType.coverColor,
        }, i);
      }, 100);
      this.checkIsStaplingAvailable(i)
    })

    this.route.queryParams.subscribe((params) => {
      // console.log("int Params")
      const referrerId = params.r;
      if (referrerId) {
        this.appService.fee = {
          userId: referrerId,
          type: "referrer",
        };
      }
      this.files = [];
      if (this.router.getCurrentNavigation()?.extras?.state) {
        // console.log(" caso anew print product....")
        this.printProductStateService.newPrintProduct(
          this.router.getCurrentNavigation().extras.state.item
        );
      } else {
        // console.log("new print product....")
        this.settings = cloneDeep(this.defaultSettings);
        this.printProductStateService.newPrintProduct();
      }
      // this.products = this.productService.getAll();
      // this.refreshProducts();
      // this.cartService.refreshCartPrice();
      this.refreshPrice();
    });

    this.homeBanner$ = this.settingService.getBanner();
    this.slider$ = this.settingService.getBanners();

    this.loading = await this.loadingController.create({
      cssClass: "my-custom-class",
      message: "Cargando archivos...",
      spinner: "bubbles",
    });
  }

  ngAfterViewInit() {
    this.widthObserver = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      this.mainContentWidth = width;
      this.cdRef.detectChanges();
    });
    this.widthObserver.observe(this.mainContent.nativeElement);

    setTimeout(() => {
      this.checkSizes()
    }, 500)

    setTimeout(() => {
      this.checkSizes()
    }, 1500)
  }

  ngOnDestroy() {
    this.componetDestroyed.next(null);
    this.componetDestroyed.unsubscribe();
    if (this.widthObserver) {
      this.widthObserver.disconnect();
    }
  }

  // Refresca los productos extras con lo que hay en el carrito
  // refreshProducts(){
  //   if (this.cartService.cart.items.length){
  //     this.cartService.cart.items.forEach((item, i) => {
  //       if (item.product.type === ProductType.STORE || item.product.type === ProductType.WALLETBONUS){
  //         const index = this.products.findIndex(product => product.product.id === item.product.id);
  //         if (index !== -1){
  //           this.products[index] = item;
  //           this.products[index].cartIndex = i;
  //         }
  //       }
  //     });
  //   }
  // }

  initSettings(setting) {
    this.settingOptions.forEach((settingOption) => {
      if (setting[settingOption] === undefined) {
        // TODO verificar si hay que colocar info extra
        setting[settingOption] =
          this.productService.getDefaultSettingOption(settingOption);
      }

      // TODO llamar a this.setSettings
      const optionSelected = setting[settingOption];
      let i = this.settings[settingOption].options.findIndex((option) => {
        return isEqual(option.value, optionSelected);
      });
      if (settingOption === 'finishing' && this.settings[settingOption].options[i].value === Finishing.BINDING && this.printProduct.product.printingGroup.files.some(f => f.coverLaminated)) {
        i++;
      }
      const orientation = this.settings['pageOrientation'].selected === 1 ? 'vertical' : 'horizontal';
      this.updateOrientationLotties(orientation);
      this.settings[settingOption].selected = i + 1;
    });
    this.settings = cloneDeep(this.settings);
  }

  updateOrientationLotties(orientation: string) {
    if (orientation === 'vertical') {
      this.settings['pageTurn'].options[0].lottie = 'assets/lotties/vertical-largo.json'
      this.settings['pageTurn'].options[1].lottie = 'assets/lotties/vertical-corto.json'
      this.settings['pagesPerSheet'].options[0].image = 'assets/icon/1pagePerSheet.svg';
      this.settings['pagesPerSheet'].options[1].image = 'assets/icon/2pagePerSheet.svg';
      this.settings['pagesPerSheet'].options[2].image = 'assets/icon/4pagePerSheet.svg';
    } else {
      this.settings['pageTurn'].options[0].lottie = 'assets/lotties/horizontal-largo.json'
      this.settings['pageTurn'].options[1].lottie = 'assets/lotties/horizontal-corto.json'
      this.settings['pagesPerSheet'].options[0].image = 'assets/icon/1pagePerSheet-horizontal.svg';
      this.settings['pagesPerSheet'].options[1].image = 'assets/icon/2pagePerSheet-horizontal.svg';
      this.settings['pagesPerSheet'].options[2].image = 'assets/icon/4pagePerSheet-horizontal.svg';
    }
    this.settings['pageTurn'] = cloneDeep(this.settings['pageTurn'])
  }

  setSettings(_settings, settingOption) {
    const i = this.settings[settingOption].options.findIndex((option) => {
      return isEqual(option.value, _settings[settingOption]);
    });
    this.settings[settingOption].selected = i + 1;
  }

  /**
   * Cuando se selecciona una opción de volteado de página.
   *
   * Si hay algún cambio en pasar l apágina o rotación,
   * ejecuta igualmente settingOptionSelect y sigue el flujo de los demás settings
   *
   * @param event
   */
  pageTurnChanged(event) {
    const { pageOrientation, pageTurn } = event;
    // si hay cambios en pasar página, se sigue con el flujo que existía anteriormente
    if (pageTurn.selected != pageTurn.prev) {
      this.settings.pageTurn.selected = pageTurn.selected;
      this.settingOptionSelect({
        setting: "pageTurn",
        option: pageTurn.selected,
      });
    }
    // Si hay cambios en la orientación se sigue con el flujo que existía anteriormente
    if (pageOrientation.selected != pageOrientation.prev) {
      this.settings.pageOrientation.selected = pageOrientation.selected;
      this.settingOptionSelect({
        setting: "pageOrientation",
        option: pageOrientation.selected,
      });
    }
  }

  /**
   * Se ejecuta al tocar algún botón de configuración.
   *
   * En caso de que esté cargando algo y selecciona un setting que pueda cambiar,
   * activará la variable changedSettings para ejecutar luego los cambios
   *
   * @param event
   */
  async settingOptionSelect(event) {
    const grouped = this.printProduct.product.printingGroup.printSettings[Settings.GROUPED]
    this.selectedPreset = "";
    if (event.setting === "segment") {
      return;
    }

    if (event.setting === 'skipFitToPage' && event.option === 1) {
      const modal = await this.modalController.create({
        component: InfoProgramacionDidacticaModalComponent,
        cssClass: "auto-height info-programacion",
        backdropDismiss: false,
      });
      modal.present();

      const res = await modal.onDidDismiss();
      if (!res.data) {
        this.settings['skipFitToPage'].selected = 2;
        this.settingOptionSelect({ setting: 'skipFitToPage', option: 2, prev: 1 });
        return;
      }
    }
    if (event.setting === "coverColor" || event.setting === "coverLaminated") {
      const onlyFirst = event.setting === 'coverLaminated' && grouped;
      this.printProduct.product.printingGroup.files.slice(0, onlyFirst ? 1 : undefined).forEach(
        (file) =>
          (file.coverColor =
            this.settings[event.setting].options[event.option - 1].value)
      );
    }
    if (event.setting === "coverLaminated") {
      this.printProduct.product.printingGroup.files.slice(0, grouped ? 1 : undefined).forEach(
        (file) =>
          (file.coverLaminated =
            this.settings[event.setting].options[event.option - 1].value)
      );
    }
    if ([Settings.LAMINATION_TYPE, Settings.LAMINATION_WEIGHT].includes(event.setting)) {
      this.printProduct.product.printingGroup.files.forEach(
        (file) =>
          (file[event.setting] =
            this.settings[event.setting].options[event.option - 1].value)
      );
    }

    if (event.setting === "pageOrientation") {
      const orientation = event.option === 1 ? 'vertical' : 'horizontal';
      this.updateOrientationLotties(orientation)
      const newPageTurn = orientation === 'vertical' ? 1 : 2
      this.settings[Settings.PAGE_TURN].selected = newPageTurn;
      this.settingOptionSelect({
        setting: Settings.PAGE_TURN,
        option: newPageTurn,
        prev: this.settings[Settings.PAGE_TURN].selected
      })
    }
    const autoRotate = this.settings[Settings.PAGES_PER_SHEET].selected === 1 && this.settings[Settings.FINISHING] !== 'stapled'

    if (event.setting === "color") {
      const allColor = this.settings[Settings.COLOR].options[event.option - 1].value === "color";
      this.printProduct.product.printingGroup.files.forEach(
        (file) =>
          (file.docColor = allColor)
      );
    }
    this.printProductStateService.setprintingGroupSettings(
      event.setting,
      event.option,
      this.settings
    );
    this.productService.checkIndividualBindingSettings(this.printProduct, event, this.settings)
    this.productService.checkDocColorsExceptions(this.printProduct)

    if (event.setting === "finishing") {
      this.onFinishingChanges(event)
    }

    if (event.setting === Settings.PAPER_WEIGHT || event.setting === Settings.FINISHING) {
      this.fileService.checkCoverTypesWeights(this.printProduct.product.printingGroup);
    }

    this.finishingBinding = this.productService.getFinishingData(
      this.printProduct.product.printingGroup.printSettings.finishing
    );
    // MN: handle trigger according to autoRotate
    // const changeThumb: boolean = ["color", "pagesPerSheet", "pageOrientation", "coverColor", "autoRotate"].includes(event.setting)
    const triggerThumbSettings = [
      "color",
      "pagesPerSheet",
      "coverColor",
      "coverLaminated",
      "pageOrientation",
      "autoRotate",
      "finishing",
      'coverType'
    ];

    const restartCoverTypeSettings = [
      "color",
      "pagesPerSheet",
      "coverColor",
      "coverLaminated",
      "twoSided"
    ]

    if (autoRotate) {
      triggerThumbSettings.push("pageTurn");
    }
    const changeThumb: boolean = triggerThumbSettings.includes(event.setting);
    const restartCoverType: boolean = restartCoverTypeSettings.includes(event.setting);

    if (restartCoverType) {
      this.fileService.restartCoverTypes(this.printProduct.product.printingGroup);
    }
    // MN: END
    this.refreshPrice();
    // Si se va a cambiar el thumb
    if (changeThumb) {
      if (!this.isLoading) {
        //FIXME Ja
        // console.log("**** tachán ***", event.setting)
        this.updateAllThumbnail(); // me estará dando aqui un error y por ende se rompe esto?
      } else {
        // console.log("**** settingsChanges ***", event.setting)
        this.settingsChanges = true;
      }
    }
    this.checkSizes()
  }

  onFinishingChanges(event) {
    const files = this.printProduct?.product.printingGroup.files;
    const finishings = this.settings[Settings.FINISHING].options;
    let finishingSelected = finishings[event.option - 1].value;
    if (finishingSelected === Finishing.BINDING && finishings[event.option - 1].extraData?.isBindingWithCoverLaminated) {
      finishingSelected = 'coverLaminated'
    }
    if (finishingSelected === 'coverLaminated') {
      this.settingOptionSelect({
        setting: 'coverLaminated',
        option: 2,
        prev: 1
      });
      // set to every file laminationWeight and laminationType that is setted in printgroup
      files.forEach(file => {
        file.laminationWeight = this.settings[Settings.LAMINATION_WEIGHT].options[this.settings[Settings.LAMINATION_WEIGHT].selected - 1].value;
        file.laminationType = this.settings[Settings.LAMINATION_TYPE].options[this.settings[Settings.LAMINATION_TYPE].selected - 1].value;
      })
    } else {
      this.settingOptionSelect({
        setting: 'coverLaminated',
        option: 1,
        prev: 2
      })
    }

    if (finishingSelected === Finishing.LAMINATED) {
      files.forEach(file => {
        file.blankSheetsBefore = 0;
        file.blankSheetsAfter = 0;
      })
    }
  }

  updateAllThumbnail() {
    this.updateLoading(true);
    const files = this.printProduct.product.printingGroup.files;
    for (let i = 0; i < files.length; i++) {
      const fileUploaded = files[i];
      this.updateFileThumbnail(fileUploaded);
    }
  }

  updateFileThumbnail(fileUploaded) {
    if (!!fileUploaded.id) {
      this.updateLoading(true);
      fileUploaded.status = PrintFileStatus.PROCESSING; // TODO, ver como quitar esto de aquí y revisar la carga del action thumb
      this.updateThumb(fileUploaded.id, fileUploaded);

    }
  }

  // Llama al servidor para cambiar el thumb y si le mandamo sfileupload colocamos el setting del cover
  updateThumb(id, fileupload ?) {
    const settings = cloneDeep(
      this.printProduct.product.printingGroup.printSettings
    );
    if (fileupload) {
      if (isNil(fileupload.coverColor)) {
        fileupload.coverColor = settings.coverColor;
      }
      fileupload.coverColor = fileupload.coverType.coverColor;
      settings.pagesPerSheet = fileupload.coverType.pagesPerSheet;
      // MN: pedir a full color si es docColor
      settings.color = fileupload.docColor || fileupload.coverColor ? PrintColor.COLOR : PrintColor.BLACKNWHITE;
      settings.pagesPerSheet = fileupload.coverColor && !fileupload.docColor ? 1 : settings.pagesPerSheet;
    }
    this.fileService.updateThumbnail(id, settings);
  }

  async fileBrowseHandler(_files) {
    if (this.cartService.cart.items.some((it) => it.product.presale)) {
      const modal = await this.modalController.create({
        component: AlertModalComponent, // AddressComponent,
        componentProps: {
          msg: "Actualmente tu carrito contiene productos en preventa que se encuentran en proceso de producción por lo que para respetar los tiempos de entrega no es posible añadir impresiones. Por favor realiza 2 pedidos independientes.",
        },
        cssClass: "auto-height",
      });
      await modal.present();
      return;
    }
    let files = this.fileDropRef.nativeElement.files;
    if (files.length === 0 && _files) {
      files = _files;
    }
    // fileBrowseHandler(files){// este files representa los archivos seleccionados para añadir
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file.size > 250 * 1024 * 1024) {
        alert(file.name + ", no se puede subir, excede los 250 MB.");
      } else if (file.type != "application/pdf") {
        alert(file.name + ", solo se permiten archivos PDF.");
      } else {
        this.files.push({
          // este es el almacenamiento de los files
          file, // este es el archivo actual a añadir en el almacenamiento de files
          isUploaded: false,
          progress: 0,
        });
        this.printProduct.product.printingGroup.files.push({
          id: null,
          originalFile: null,
          originalType: null,
          printFile: null,
          pages: 0,
          thumbFile: null,
          status: PrintFileStatus.NEW,
          coverColor: false,
          coverLaminated: false,
          docColor: false
        });
      }
    }
    this.fileDropRef.nativeElement.value = "";
    //activamos la generación de imágenes
    this.onPdfConverted();
  }

  fileAction(event, i) {
    switch (event.action) {
      case "new":
        // Se agrega siempre a PrintGroup
        this.newPrintGroupFile(i);

        break;
      case "updateStatus":
        this.printProduct.product.printingGroup.files[i].alias = this.printProduct.product.printingGroup.files[i].originalFile.name
        this.checkFileError(i);
        break;
      case "delete":
        this.delPrintGroupFile(i);
        break;
      case "reorder":
        this.printProductStateService.refresh();
        break;
      case "fail":
        break;
      case "refreshPrice":
      case "uploaded":
        // El archivo subió con éxito. Se actualiza el printGroup
        // this.updatePrintGroupFile(i);
        this.refreshPrice();
        break;

      case "updateThumb":
        // Al obtener un nuevo thumb, debemos actualizar lo del printGroup,
        // pero en teoría ya no hace falta porque se tiene asociado el mismo file
        // this.updatePrintGroupThumb(i, event.fileUploaded)
        this.cartService.saveCart(this.cartService.cart);
        break;
      case "thumbnail":
        // TODO revisar si hace falta realmente manejar este evento
        // Al finalizar de cargar se lanza el evento de uploadThumb para que actualice el thumb
        // Creo que no hace fata ya que se maneja por lo settings
        // if(!this.settingsChanges){
        //   this.updateThumb(event.id);
        // }
        break;
      // case 'coverColor':
      //   this.setFileCoverColor(event, i)
      //   break;
      // case 'docColor':
      //   this.setDocOptionColor(event, i)
      //   break;
      case "docOptions":
        this.setDocOptionColor(event, i);
        break;
      case "blankSheets":
        this.setBlankSheets(event, i);
        break;
    }
    this.updateLoading();
  }

  setDocOptionColor(event, i) {
    const prevColorThumbnail =
      this.printProduct.product.printingGroup.files[i].docColor ||
      this.printProduct.product.printingGroup.files[i].coverColor;
    const docColor = event.setting === 'docColor' ? event.value : this.printProduct.product.printingGroup.files[i].docColor;
    const coverLaminated = event.setting === 'coverLaminated' ? event.value : this.printProduct.product.printingGroup.files[i].coverLaminated;
    const coverColor = event.setting === 'coverColor' ? event.value : this.printProduct.product.printingGroup.files[i].coverColor || coverLaminated;
    this.printProduct.product.printingGroup.files[i].docColor = docColor;
    this.printProduct.product.printingGroup.files[i].coverColor = coverColor;
    this.printProduct.product.printingGroup.files[i].coverLaminated = coverLaminated;

    this.productService.checkDocColorsExceptions(this.printProduct)
    this.refreshPrice();
    this.printProductStateService.refresh();
    this.cartService.saveCart(this.cartService.cart);

    // Si cambia de color a bn se manda a actualizar el thumb
    // MN: si es a 2 o 4 páginas por cara, y el documento tiene más de 1 página,
    //     considerar regenerar el thumb con full color si es docColor
    if (event.setting === 'docColor')
      this.fileService.restartCoverTypes(this.printProduct.product.printingGroup, i)
    const multiPage =
      this.printProduct.product.printingGroup.printSettings.pagesPerSheet !==
      PagesPerSheet.ONE &&
      this.printProduct.product.printingGroup.files[i].pages > 1;
    if (prevColorThumbnail !== (docColor || coverColor) || multiPage) {
      this.updateFileThumbnail(
        this.printProduct.product.printingGroup.files[i]
      );
    }
  }

  setBlankSheets(event, i) {
    this.printProduct.product.printingGroup.files[i].blankSheetsBefore =
      event.blankSheetsBefore;
    this.printProduct.product.printingGroup.files[i].blankSheetsAfter =
      event.blankSheetsAfter;
    this.refreshPrice();
    this.printProductStateService.refresh();
    this.cartService.saveCart(this.cartService.cart);
  }

  async checkFileError(i) {
    if (
      this.printProduct.product.printingGroup.files[i].status !==
      PrintFileStatus.FAILED
    ) {
      return;
    }
    const printProtected =
      this.printProduct.product.printingGroup.files[i].printProtected;
    const printAllowed =
      this.printProduct.product.printingGroup.files[i].printAllowed;
    if (
      !printAllowed &&
      printProtected &&
      !this.acceptanceService.getPrintAllowed() &&
      !this.protectedPrintModalOpened
    ) {
      this.protectedPrintModalOpened = true;
      const modal = await this.modalController.create({
        component: PrintPermissionPolicyComponent,
        cssClass: "auto-height",
        backdropDismiss: false,
      });
      modal.present();

      await modal.onDidDismiss().then((res) => {
        if (res.data) {
          const { action } = res.data;
          if (action === "reject") {
            this.deleteProtectedFiles();
          } else {
            this.acceptProtectedFiles();
          }
        }
        this.protectedPrintModalOpened = false;
      });
    }
  }

  delPrintGroupFile(i) {
    this.files.splice(i, 1);
    this.printProduct.product.printingGroup.files.splice(i, 1);
    if (!this.printProduct.product.printingGroup.files.length) {
      this.cartService.delPrintProduct(this.printProduct);
    }
    this.productService.checkDocColorsExceptions(this.printProduct)
    this.refreshPrice();
    this.printProductStateService.refresh();
  }

  newPrintGroupFile(i) {
    const printGroupExist = this.cartService.checkPrintGroupExist(
      this.printProduct
    );
    if (
      !printGroupExist &&
      this.printProduct.product.printingGroup.files.length
    ) {
      this.cartService.addPrintProduct(this.printProduct);
    }
    this.productService.checkIndividualBindingSettings(this.printProduct, null, this.settings)
    this.refreshPrice();
    this.printProductStateService.refresh();
  }

  async selectProduct(event, index) {
    const popover = await this.popoverController.create({
      component: ProductQtyComponent,
      componentProps: { product: this.products[index] },
      cssClass: "popup-product-qty",
      translucent: true,
    });

    popover.onDidDismiss().then((res) => {
      if (res.data >= 0) {
        this.products[index].qty = res.data;
      }
      this.products[index].price.amt =
        this.products[index].product.unitPrice.amt * this.products[index].qty;
      // Colocamos la cantidad y modificamos o eliminamos el item en el carrito
      this.cartService.update(this.products[index]);
    });
    return await popover.present();
  }

  refreshPrice() {
    this.productService.setPrintProduct(this.printProduct, this.files);
    this.cartService.refreshCartPrice();
    this.cartService.setCartBindingUses();
  }

  onQtyChanged(type, value) {
    const prevValue = this.printProduct.qty;
    this.printProduct.qty =
      type === "inc" ? this.printProduct.qty + value : value;

    if (this.printProduct.qty < 1) {
      this.printProduct.qty = 1;
    }
    this.refreshPrice();
    const canIncrease = this.canIncreaseDuplicates() || value === -1;

    if (!canIncrease) {
      this.printProduct.qty = prevValue;
      // console.log("this.qtyInput", this.qtyInput)
      this.qtyInput.value = prevValue;

      this.toastController
        .create({
          message:
            "No hay stock suficiente para aumentar el número de duplicados con la configuración de colores actual.",
          color: "danger",
          position: "middle",
          cssClass: "fitWidthToast",
          duration: 5000,
        })
        .then((toast) => {
          toast.present();
        });
      this.refreshPrice();
      return;
    }
  }

  // qtyChanged(event) {
  //   console.log("qtyChanged", event)
  //   const newValue = event.detail?.value ?? event;
  //   if (newValue < 1) {
  //     setTimeout(() => {
  //       this.printProduct.qty = 1;
  //     }, 1);
  //   }
  //   this.refreshPrice();
  // }

  // changeValueOfDuplicates(value: number) {
  //   console.log("Duplicadosoooo")
  //   const canIncrease = this.canIncreaseDuplicates() || value === -1;
  //   if (!canIncrease) {
  //     this.toastController.create({
  //       message: 'No hay stock suficiente para aumentar el número de duplicados con la configuración de colores actual.',
  //       color: 'danger',
  //       position: 'middle',
  //       cssClass: 'fitWidthToast',
  //       duration: 5000
  //     }).then(toast => {
  //       toast.present();
  //     });
  //     return;
  //   }
  //   this.printProduct.qty += value;
  //   if (this.printProduct.qty === 0) {
  //     this.printProduct.qty = 1;
  //   }
  //   this.refreshPrice();
  // }

  canIncreaseDuplicates() {
    if (!this.printProduct.product.printingGroup.binding) {
      return true;
    }
    let canIncrease = true;
    this.printProduct.product.printingGroup.binding.covers.forEach((cov) => {
      const alreadyInCart = this.cartService.cartBindingStockUses.covers.find(
        (el) => el.color === cov.color && el.pageSize === cov.pageSize
      );
      const currentStock = this.cartService.bindingStock.covers.find(
        (el) => el.color === cov.color && el.pageSize === cov.pageSize
      );
      if (
        alreadyInCart &&
        currentStock &&
        currentStock.stock - alreadyInCart.uses < 0
      ) {
        canIncrease = false;
        return;
      }
    });
    if (canIncrease) {
      this.printProduct.product.printingGroup.binding.rings.forEach((cov) => {
        const alreadyInCart = this.cartService.cartBindingStockUses.rings.find(
          (el) => el.color === cov.color && el.diameter == cov.diameter
        );
        const currentStock = this.cartService.bindingStock.rings.find(
          (el) => el.color === cov.color && el.diameter == cov.diameter
        );
        if (
          alreadyInCart &&
          currentStock &&
          currentStock.stock - alreadyInCart.uses < 0
        ) {
          canIncrease = false;
          return;
        }
      });
    }
    return canIncrease;
  }

  async reload() {
    if (this.cartService.hasErrors()) {
      const toast = await this.toastController.create({
        message:
          "Algunos archivos tienen errores. Elimínalos antes de continuar.",
        color: "danger",
        position: "middle",
        cssClass: "fitWidthToast",
        duration: 2000,
      });
      await toast.present();
      return;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/"]);
  }

  //FIXME JA isLoading
  updateLoading(forceStatus = false) {
    if (forceStatus) {
      this.isLoading = true;
      return;
    }
    // let statusLoading = false;

    const files = this.printProduct.product.printingGroup.files;

    // files.forEach((file) => {
    //   if (
    //     !(
    //       file.status === PrintFileStatus.FINISHED ||
    //       file.status === PrintFileStatus.FAILED
    //     )
    //   ) {
    //     statusLoading = true;
    //   }
    // });

    this.isLoading = this.files.length > 0 && !!files.find(file => !(file.status === PrintFileStatus.FINISHED || file.status === PrintFileStatus.FAILED));


    // console.log("this.isloading", this.isLoading, this.files.length, files.find(file => !(file.status === PrintFileStatus.FINISHED || file.status === PrintFileStatus.FAILED))?.status);
    this.menuCtrl.swipeGesture(!this.isLoading);

    if (this.files.length == 0) {
      this.settingsChanges = false;
      return;
    }

    // si termina de cargar y se han cambiado los settings....
    if (!this.isLoading) {
      if (this.settingsChanges) {
        this.settingsChanges = false;
        this.updateAllThumbnail();
      }
      this.refreshPrice();
    }
  }

  truncate(num, index = 2) {
    // cutting the num
    return +num.toString().slice(0, num.toString().indexOf(".") + (index + 1));
  }

  openGoogleDrivePicker(): void {
    this.googleDrivePickerService.open(async (data) => {
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        this.loading.present();
        const docs = data[google.picker.Response.DOCUMENTS];
        const files = await this.googleDrivePickerService.getFiles(docs);
        this.loading.dismiss();
        this.fileBrowseHandler(files);
      }
    });
  }

  openDropboxPicker() {
    this.dropboxPickerService.open();

    this.dropboxPickerService.getResponse().subscribe((response) => {
      this.fileBrowseHandler(response);
    });
  }

  isDropboxSupported() {
    try {
      return Dropbox.isBrowserSupported();
    } catch (e) {
      return false;
    }
  }

  public

  dropped(files
          :
          NgxFileDropEntry[]
  ) {
    this.filesDrag = files;
    const filesToAdd = [];
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileBrowseHandler([file]);
          // Here you can access the real file
          // console.log(droppedFile.relativePath, file);

          /**
           // You could upload it like this:
           const formData = new FormData()
           formData.append('logo', file, relativePath)

           // Headers
           const headers = new HttpHeaders({
           'security-token': 'mytoken'
           })

           this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
           .subscribe(data => {
           // Sanitized logo returned from backend
           })
           **/
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    this.dragOver = true;
  }

  public fileLeave(event) {
    this.dragOver = false;
  }

  public isDesktop() {
    return this.platform.is("desktop") || this.isWindows();
  }

  private isWindows() {
    if (!window || !navigator) {
      return false;
    }

    const a: string =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    return /windows/i.test(a);
  }


  showPresets() {
    this.showingPresets = !this.showingPresets;
    const div = document.querySelector("#presets-div");
    this.presetsHeight = this.showingPresets ? div.scrollHeight + "px" : "0";
  }

  showPrintSettings() {
    this.showingPrintSettings = !this.showingPrintSettings;
    const div = document.querySelector("#print-settings-div");
    this.printSettingsHeight = this.showingPrintSettings
      ? div.scrollHeight + "px"
      : "0";
  }

  showFinishSettings() {
    this.showingFinishSettings = !this.showingFinishSettings;
    const div = document.querySelector("#finish-settings-div");
    this.finishSettingsHeight = this.showingFinishSettings
      ? div.scrollHeight + "px"
      : "0";
  }

  activePreset(preset
               :
               any
  ) {
    this.settings = cloneDeep(this.defaultSettings);
    this.selectedPreset = preset.name;
    if (preset.default) {
      return;
    }
    Object.keys(preset).forEach((prop) => {
      if (!this.settings[prop]) {
        return;
      }
      this.settings[prop].selected = preset[prop];
    });
  }

  onlyShippingCalculated() {
    return (
      this.cartService.cart.shipping.price.amt ===
      this.cartService.cart.price.totalAmt
    );
  }

  private checkScreenWidth() {
    this.isLargeScreen = window.innerWidth >= 768;
  }

  private deleteProtectedFiles() {
    localStorage.setItem("printAllowed", this.encryptS.encrypt("false"));
    const files = cloneDeep(this.printProduct.product.printingGroup.files);

    for (let i = files.length - 1; i >= 0; i--) {
      const file = files[i];
      if (file.printProtected) {
        this.delPrintGroupFile(i);
      }
    }
  }

  private acceptProtectedFiles() {
    localStorage.setItem("printAllowed", this.encryptS.encrypt("true"));
    const filesToProcess = this.printProduct.product.printingGroup.files.filter(
      (file) => file.printProtected && !file.printAllowed
    );
    const filesToChangeStatusUploaded = this.files.filter((file) =>
      filesToProcess.some(
        (fileProc) => file.file && fileProc.originalFile.name === file.file.name
      )
    );
    filesToChangeStatusUploaded.forEach((file) => {
      file.isUploaded = false;
    });
    filesToProcess.forEach((file) => {
      this.fileService
        .processFile(
          file.id,
          this.printProduct.product.printingGroup.printSettings,
          "uploads"
        )
        .catch();
    });
  }

  onPdfConverted(event = null) {
    if (event) {
      const { type, payload } = event;
      const file = this.files.find((f) => f.file == event.payload.file);
      file.pdfImages = payload.pdfImages;
    }
    const fileNoThumb = this.files.find((f) => f.file && !f.pdfImages);
    if (fileNoThumb) {
      this.pdfToConvert = fileNoThumb.file;
    }
  }

  private handleCoverLaminatedConfigurations() {
    const files = this.printProduct?.product.printingGroup.files;
    const coverLaminatedCount = files?.filter(fil => fil.coverLaminated).length;
    const printSettings = this.printProduct?.product.printingGroup.printSettings;

    if (printSettings && printSettings?.[Settings.COVER_LAMINATED] && (printSettings[Settings.PAGE_SIZE] === PageSize.A3 ||
      printSettings[Settings.FINISHING] !== Finishing.BINDING)) {
      printSettings[Settings.COVER_LAMINATED] = false;
      this.settingOptionSelect({
        setting: 'coverLaminated',
        option: 1,
        prev: 2
      });
    } else if (printSettings?.[Settings.COVER_LAMINATED]) {
      if (printSettings.hardCoverFront.id !== 'transparent') {
        this.settings.hardCoverFront.selected = 1;
        this.settingOptionSelect({
          setting: 'hardCoverFront',
          option: 1,
          prev: 2
        });

      }
      files.forEach(file => {
        if (file.hardCoverFront.id !== 'transparent') {
          file.hardCoverFront = this.settings[Settings.HARD_COVER_FRONT].options[0].value;
        }
        if (file.coverLaminated) {
          file.blankSheetsBefore = 0;
          if (file.pages === 1) {
            file.blankSheetsAfter = 0;
          }
        }
      })
      if (printSettings[Settings.GROUPED] && ((files.length && !files[0].coverLaminated && printSettings?.[Settings.COVER_LAMINATED]) || coverLaminatedCount > 1)) {
        files.forEach(file => {
          if (file.coverLaminated) {
            file.coverColor = false;
            file.coverLaminated = false;
          }
        })
        this.settingOptionSelect({
          setting: 'coverLaminated',
          option: 2,
          prev: 1
        });
      } else if (printSettings?.[Settings.COVER_LAMINATED] && coverLaminatedCount !== files.length && !printSettings[Settings.GROUPED]) {
        this.settingOptionSelect({
          setting: 'coverLaminated',
          option: 2,
          prev: 1
        });
      }
    }
  }

  getCartTotal() {
    return this.onlyShippingCalculated() ? 0 : Math.max(this.cartService.cart.price.totalAmt - this.cartService.cart.shipping.price.amt, 0)
  }

  protected readonly Settings = Settings;
  protected readonly PageSize = PageSize;

  private handleScroll(e) {
    if (e.target.classList.contains("on-scrollbar") === false) {
      e.target.classList.add("on-scrollbar");
    }
    // Cancela el temporizador anterior si existe
    if (this.timeoutScrollId !== null) {
      clearTimeout(this.timeoutScrollId);
    }

    // Crea un nuevo temporizador
    this.timeoutScrollId = setTimeout(() => {
      e.target.classList.remove("on-scrollbar");
      this.timeoutScrollId = null; // Resetea el identificador del temporizador después de que se ejecute
    }, 1000);
  }

  private checkSectionHeights() {
    setTimeout(() => {
      const settingsSection = document.querySelector('section.settings');
      const buttonSelector = document.querySelector('.settings_selectorButtonContainer');
      const sectionTitle = document.querySelector('.section-title');
      const summaryNoFoldersSection = document.querySelector('#no-folders-section');
      const groupsTitle = document.querySelector('.groups_title');
      this.settingsScrollHeight = settingsSection?.clientHeight - buttonSelector?.clientHeight - sectionTitle?.clientHeight - 50
      this.summaryScrollHeight = settingsSection?.clientHeight - summaryNoFoldersSection?.clientHeight - groupsTitle?.clientHeight
    }, 200)

  }

  private checkSettingsWidth() {
    const settingsSection = document.querySelector('section.settings');
    this.settingsWidth = settingsSection?.clientWidth;
  }

  private checkSizes() {
    requestAnimationFrame(() => {
      this.checkScreenWidth();
      this.checkSectionHeights()
      this.checkSettingsWidth();
    });
  }

  mobileBarButtonClicked(event: any) {
    if (event === 'settings') {
      this.showSettings = true;
    } else if (event === 'groups') {
      this.showFolders = true;
    }
  }

  private checkIsStaplingAvailable(fileIndex: any) {
    const coverType = this.printProduct.product.printingGroup.files[fileIndex].coverType
    const isStaplingSelected = this.settings[Settings.FINISHING].options[this.settings[Settings.FINISHING].selected - 1].value === Finishing.STAPLED
    const printGroupPaperWeight = this.settings[Settings.PAPER_WEIGHT].options[this.settings[Settings.PAPER_WEIGHT].selected - 1].value

    if (isStaplingSelected && (!coverType.default || printGroupPaperWeight !== coverType.paperWeight)) {
      this.settings[Settings.FINISHING].selected = 1;
      this.settingOptionSelect({
        setting: Settings.FINISHING,
        option: 1,
        prev: this.settings[Settings.FINISHING].selected
      })

      let message = 'Se ha desactivado el grapado ya que no está disponible para configuraciones de portada distintas a la predeterminada';
      this.toastController.create({
        message: message,
        color: 'danger',
        position: 'middle',
        cssClass: 'fitWidthToast',
        duration: 5000
      }).then(toast => {
        toast.present();
      });
    }
  }
}
