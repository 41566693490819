/**
 * Componente que sirve de Adapter para hacer Búsquedas en Elastic Search
 * Version: 2020-12-06
 * Cambios:
 *  2021-08-18 Se agrega la opción de nested IMPORTANTE COLOCAR EN LA DE ANGULAR LIB
 *  2021-03-23 Se cambia la llamada a currentUSer importando de angularfireAuth
 *  2020-12-06 Se cambia completamente la función _getFilter para poder buscar parcialmente y por negación
 *  2020-09-09 Se agrega la opcion de options para el body
 *  2020-09-04 Se agrega la opción para buscar por rango de fechas
 *  2020-08-02 Se coloca la opcion para configuarra environment externamente setEsConfig (esto pasará a un modulo inciable)
 *  2020-05-11 Se coloca la opcion para ir directamente a es
 *  2020-04-14 Se im implemntó el uso de Extras para hacer búsquedas con WildCard y Nested
 */

import { Injectable, NgZone, inject } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { format } from 'date-fns'
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {
  private environment: any;
  private functions: AngularFireFunctions = inject(AngularFireFunctions);
  private fn = this.functions.httpsCallable('search');
  private fn2 = this.functions.httpsCallable('search2');
  private url;

  constructor(
    private afAuth: AngularFireAuth,
    public zone: NgZone,
    private httpClient: HttpClient
  ) {
  }

  setEsConfig(environment): void {
    this.environment = environment;
    this.url = this.environment.elasticSearch.url;
  }

  async rq(path, body) {
    //FIXME: Aparentemente despues de hibernar tarda en obtener el currentUser debemos colocar un spinner.
    const currentUser = await this.afAuth.currentUser;
    if (currentUser) {
      return currentUser.getIdToken(/* forceRefresh */ false).then(idToken => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${idToken}`,
          'project-id': `${this.environment.firebaseConfig.projectId}`
        });
        return this.httpClient.request('POST', this.url + path, { headers, body }).toPromise();
      })
    } else {
      return Promise.reject(new Error('fail-invalid currentUser'));
    }
  }

  a2f(body) {
    return this.rq('search', body);
  }

  getDashboard(paths, terms) {
    const arrQry = [];
    paths.forEach(path => arrQry.push(this.rq(path, terms)));
    return Promise.all(arrQry);
  }

  /**
   * Buscar por Indice en Elastic
   * @param index Indice de la "colección"
   * @param body Parámetros de Busqueda en formato ElasticSearch
   * @param serialize (Opcional) Serializa los datos que llegan de elastic search y transforma a como lo necesitamos.
   * @param options (Opcional) Permite especificar opciones adicionales.. si se envía body:true devuelve elbody del query

   */
  query(index, body, serialize?, options?): Promise<any> {
    // console.log("Index: ", index);
    // console.log("body: ", body);
    if (!index || !body) Promise.reject("mu mal");
    return this.a2f({ index, body })
      .then((result: any) => {
        let response = result;
        let data = response.hits.hits.map(item => {
          const id = item._id;
          //Serializamos por si en la consulta hay datos que transformar, lo hará cada responsable
          let source = serialize ? serialize(item._source) : item._source;
          return { id, ...source };
        })
        let _meta = {
          count: response.hits.total.value,
          body: null
        }
        if (options?.body) _meta.body = body;
        return { data, _meta }
      })
      .catch(err => console.error("Es error: ", err));
  }

  //helper para convertir los datos que recibimos de forma estandar desde MatTable, quien use es debe llamar
  //Esta función para hacer el cambio
  // Ejemplo de Extras
  // let extras = {
  //   vehicles_idType:{
  //     nested:"vehicles"
  //   },
  //   vehicles_year:{
  //     nested:"vehicles"
  //   },
  //   vehicles_color:{
  //     nested:"vehicles"
  //   },
  //   vehicles_idBrand:{
  //     nested:"vehicles"
  //   },
  //   vehicles_numberPlate:{
  //     nested:"vehicles",
  //     type:"wildcard"
  //   }
  // }
  mtToBody(_paginator, _sort, _filter, extras?) {
    let from = _paginator.pageIndex * _paginator.pageSize;
    let size = _paginator.pageSize;
    let queryTerm = this._getFilter(_filter, extras);
    //Armamos el Sort
    let sort = {}
    if (_sort.active && _sort.direction.length) {
      let index = _sort.active;
      // console.log("Index Sort", index);
      sort = [{
        ...(index !== 'relevance' && { [index]: { order: _sort.direction } }),
        ...(index === 'relevance' && {
          _script: {
            "type": "number",
            "script": {
              "lang": "painless",
              "source": "if(doc['relevance'].value < 64) {return 0} else {return 1}",
              "params": {
                "factor": 1.1
              }
            },
            "order": "desc"
          },
          _score: { order: "desc" },
          relevance: { order: "desc" }
        })
      }]
    } else {
      sort = {} //Default
    }
    let query = {
      //"match_all": {}
      bool: queryTerm
    }
    return { query, from, size, sort }
  }

  // No debería usarse
  // agg(index, body):Promise<any>{
  //   // return this.fn2({index, body}).toPromise()
  //   return this.a2f({index, body})
  //   .then((result:any)=>{
  //     // console.log("agg", result);
  //     return result.aggregations.activationGroups.buckets;
  //   })
  // }

  //Esto transforma el a2filter a filter es
  private _getFilter(filters, extras?) {
    const query = {
      must: [],
      filter: [],
      must_not: []
    }
    Object.keys(filters).forEach(_key => {
      const type = (extras && extras[_key] && extras[_key].type) ? extras[_key].type : 'term';
      const queryTerm = {
        must: [],
        filter: [],
        must_not: []
      }
      filters[_key].forEach(filter => {
        let values = Array.isArray(filter.value) ? filter.value : [filter.value];
        let op = filter.op;
        let key = filter.key;
        let queryOption = op == '!==' ? 'must_not' : 'must';

        values.forEach(value => {
          switch (type) {
            case 'id':
              queryTerm[queryOption].push(this._getId(key, value));
              break;
            case 'term':
              queryTerm[queryOption].push(this._getTerm(key, value));
              break;
            case 'wildcard':
              queryTerm[queryOption].push(this._getWilcard(key, value));
              break;
            case 'dateRange':
              queryTerm[queryOption].push(this._getDateRange(key, value));
              break;
            case 'search':
              queryTerm[queryOption].push(this._getSearch(value.value, value.terms));
              break;
            case 'text':
              queryOption = op == '!==' ? 'must_not' : 'must';
              queryTerm[queryOption].push(this._getText(key, value));
              break;
          }
        });
      })

      //si cualquiera de los elementso dentro de queryTerm tiene mas de uno se convierte en
      Object.keys(queryTerm).forEach(key => {
        if (queryTerm[key].length > 1) {
          queryTerm[key] = [{
            "bool": {
              "should": queryTerm[key],
              "minimum_should_match": 1
            }
          }]
        }
      })

      //verificamos si es nested
      if (extras && extras[_key] && extras[_key].nested) {
        Object.keys(queryTerm).forEach(key => {
          if (queryTerm[key].length) {
            queryTerm[key] = [{
              nested: {
                path: extras[_key].nested,
                query: {
                  bool: {
                    filter: queryTerm[key]
                  }
                }
              }
            }]
          }
        })
      }
      //Asignamos al query principal los querys de la propiedad al apartado que pertenece
      Object.keys(query).forEach(key => query[key].push(...queryTerm[key]));

    })
    return query;

  }

  private _getSearch(searchValue, searchTerms) {
    const fields = [];
    searchTerms.forEach(term => {
      term.score ? fields.push(`${term.field}^${term.score}`) : fields.push(`${term.field}`);
    });
    return {
      multi_match: {
        query: searchValue,
        fields,
        type: 'most_fields',
        fuzziness: "AUTO"
      }
    };
  }

  private _getWilcard(key, value) {
    return { "wildcard": { [key]: /[*?]/.test(value) ? value : '*' + value + '*' } };
  }

  private _getDateRange(key, value) {
    const _format = 'yyyy-MM-dd'
    const fi = format(value.fi, _format);
    const fe = format(value.fe, _format);

    return {
      range: {
        [key + '._seconds']: {
          "format": _format,
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone, //"Europe/Madrid",
          "gte": fi + "||/d",
          "lte": fe + "||/d"
        }
      }
    }
  }

  private _getTerm(key, value) {
    return { "term": { [key]: (typeof value === 'object') ? value.id : value } };
  }

  private _getId(key, value) {
    return { "ids": { values: [(typeof value === 'object') ? value.id : value] } };
    // return  {"id" : {[key] : (typeof value === 'object') ? value.id : value }};
  }

  private _getText(key, value) {
    const match = value.split(" ").length;
    return { "match_bool_prefix": { [key]: { "query": value, "minimum_should_match": match } } };
  }

  //Obsoltea
  private _getFilter2(filters, extras?) {
    //Se genera un filtro adecuado para elasticsearch
    let retFilter = [];
    Object.keys(filters).forEach(key => {
      if (filters[key].length) {
        //Acá podemos determinar si es wildcard o Match
        //Si tiene mas de un valor para un termino de búsqueda se usa Terms
        let _filter;
        //Se procesa el tipo de busqueda (posteriormente se manejara todo a partir de aca)
        if (extras && extras[key] && extras[key].type) {
          let _key = filters[key][0].key;
          switch (extras[key].type) {
            case "wildcard":
              const wValue = /[*?]/.test(filters[key][0].value) ? filters[key][0].value : '*' + filters[key][0].value + '*';
              _filter = {
                wildcard: { [_key]: wValue }
              }
              break;
            case "dateRange":
              const _format = 'yyyy-MM-dd'
              const fi = format(filters[key][0].value.fi, _format);
              const fe = format(filters[key][0].value.fe, _format);
              _filter = {
                range: {
                  [_key + '._seconds']: {
                    "format": _format,
                    "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone, //"Europe/Madrid",
                    "gte": fi + "||/d",
                    "lte": fe + "||/d"
                  }
                }
              }
              break;

            default:
              break;
          }
          // console.log("Filter", _filter)

        } else {
          _filter = filters[key].length > 1 ? { terms: {} } : { term: {} }
          filters[key].forEach(filter => {
            // console.log("Filter", filter);
            let value = (typeof filter.value === 'object') ? filter.value.id : filter.value;
            // console.log("Value", value);
            // console.log("is Array", Array.isArray(filter.value));
            if (_filter.term) {
              //Solo hay uno por lo tanto se setea term
              _filter.term = { [filter.key]: value };
            } else {
              if (!_filter.terms[filter.key]) {
                //si no existe el seteo del termino, se realiza para luego hacer push de los valores
                _filter.terms = { [filter.key]: [] };
              }
              _filter.terms[filter.key].push(value);
            }
          })
        }

        let pFilter;
        pFilter = _filter;
        if (extras) {
          if (extras[key] && extras[key].nested) {
            let nested = {
              path: extras[key].nested,
              query: {
                bool: {
                  filter: _filter
                }
              }
            }
            pFilter = { nested: nested };
          }
        }
        //Acá deberiamos ver si es nested para colocar el objeto nested junto al path
        retFilter.push(pFilter);
      }
    })
    return { filter: retFilter };
  }

  _API_call = (path, data = null): Observable<any> => {
    return this.functions.httpsCallable(path)(data);
  }

  _API_search<T>(options: T | Array<T>): Observable<any> {
    const query = Array.isArray(options) ? { queries: options } : options;
    const API_SEARCH = 'api2/search';
    return this._API_call(API_SEARCH, query);
  }
}
