import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from "lodash";

@Component({
  selector: 'app-variant-chips',
  templateUrl: './variant-chips.component.html',
  styleUrls: ['./variant-chips.component.scss']
})
export class VariantChipsComponent implements OnInit {
  @Input() options = [];
  @Input() selected = null;
  @Input() name = "option";
  @Input() available = [];
  @Input() galleries;
  @Input() variants;
  @Input() selectedVariant;
  @Input() optionsSelected;
  @Output() changes = new EventEmitter<any>();
  @Output() nextStep = new EventEmitter<any>()
  @Input() isProductCard: boolean = false;
  @Input() properties
  @Output() restartVariantSelected = new EventEmitter<unknown>();


  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    window.addEventListener('load', this.checkOverFlow);
    window.addEventListener('resize', this.checkOverFlow);
    this.checkOverFlow();
  }

  onChange(value) {
    // console.log("Variant1", value);
    // console.log("selected", this.selected);
    this.changes.emit({ option: value })
  }

  isDisabled(option) {
    return this.available ? !this.available.includes(option) : true;
  }

  clickInput(id: string) {
    this.nextStep.emit();

    document.getElementById(id).click();
  }

  getVariantDiscount(option: any) {
    const variant = this.variants.find(v => v.optionsValue[0] === option);
    return variant ? variant.discount : 0;
  }

  checkOverFlow() {
    const container = document.querySelector('.selection-group') as HTMLDivElement;

    if (!container) return;

    if (container.scrollHeight > container.clientHeight) {
      container.style.overflowY = 'auto'
    } else {
      container.style.overflowY = 'unset'
    }
  }

  isVisible(option: any) {
    let selectedProperties: any = {};

    if (this.selectedVariant) {
      // Si hay una variante seleccionada, clonar sus propiedades
      selectedProperties = { ...this.selectedVariant.properties };
      selectedProperties[this.name] = option;

      // Buscar una variante con las mismas propiedades
      const foundVariant = this.variants.find(variant =>
        Object.keys(variant.properties).every(key =>
          variant.properties[key] === selectedProperties[key]
        )
      );

      if (foundVariant) {
        // Si se encuentra la variante, verificar si es visible
        const isVisible = foundVariant.visible !== false;

        // Si no es visible y las propiedades son iguales a las de la variante seleccionada, emitir evento
        if (!isVisible && Object.keys(selectedProperties).every(key =>
          selectedProperties[key] === this.selectedVariant.properties[key])
        ) {
          this.restartVariantSelected.emit(true);
        }
        return isVisible;
      }
    } else {
      // Si no hay variante seleccionada, construir las propiedades seleccionadas
      for (let i = 0; i < this.properties.length; i++) {
        const property = this.properties[i];
        selectedProperties[property.name] = this.optionsSelected[i] || null;
      }
      selectedProperties[this.name] = option;

      // Buscar una variante visible con las propiedades seleccionadas (ignorando las nulas)
      return this.variants.some(variant =>
        Object.keys(variant.properties).every(key =>
          selectedProperties[key] === null || variant.properties[key] === selectedProperties[key]
        ) && variant.visible !== false
      );
    }

    return true;
  }

}
