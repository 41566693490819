import { LaminationType, LaminationWeight, PaperWeight, Settings } from './../models/models';
import { UserService } from './../core/user.service';
import { SettingService } from './setting.service';
import { BehaviorSubject } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
  BankInformation,
  FeeType,
  Finishing,
  PageOrientation,
  PageSize,
  PagesPerSheet,
  PageTurn,
  PrintColor
} from '../models/models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Setting } from '../models/setting.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { auth } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { ModalController, Platform } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import { Product, ProductType } from '../models/product.model';
import { ShoppingCart } from '../models/cart.model';
// import firebase from "firebase/compat/app";
// import firebase from '@firebase/app-compat';
import firebase from '@firebase/app-compat';
import { OrdersBlockedAlertComponent } from "../components/orders-blocked-alert/orders-blocked-alert.component";

const auth = firebase.auth;


@Injectable({
  providedIn: 'root'
})
export class AppService {
  private isInit = new BehaviorSubject<boolean>(false);
  public isInit$ = this.isInit.asObservable();

  public options = environment.options;

  private readonly _generalSettings = new BehaviorSubject<Setting.General>({
    homeBanner: '',
    fees: {
      share: 0,
      referrer: 0,
      coupon: 0,
      creator: 0,
      royalty: 0,
    },
    influencerText: '',
    minVersion: null,
    inMaintenance: false,
    ordersBlockedTitle: '',
    ordersBlockedBody: ''
  });
  ordersBlockedTitle: ''
  ordersBlockedBody: ''
  isOrdersBlocked = false;
  readonly generalSettings$ = this._generalSettings.asObservable();

  private userService: UserService = inject(UserService);


  public isLogged$ = this.userService.user$.pipe(map(user => {
    return user && !user.isAnonymous;
  }));

  public isntAceptanceAmbassador$ = this.userService.user$.pipe(map(user => {
    return user && !user?.acceptance?.referrer;
  }));

  isMobile = false;

  appBanners: Setting.AppBanners;

  fee = {
    userId: null as string,
    type: null as FeeType
  };
  referrerId: string = null;
  bankInformation: BankInformation;
  cur = 'EUR';
  taxPct = 21;

  _ringColors = [];
  // _ringColors = [
  //   { name: 'Transparente', color: '#FFFFFF', id: 'transparent' /*, default: true */ },
  //   { name: 'Negro',        color: '#000000', id: 'black'       },
  //   { name: 'Turquesa',     color: '#40E0D0', id: 'turquoise'   },
  //   { name: 'Rosa Pastel',  color: '#FFC0CB', id: 'light-pink'  },
  //   { name: 'Lila',         color: '#9370DB', id: 'lilac'       },
  //   { name: 'Azul Pastel',  color: '#87CEFA', id: 'light-blue'  },
  //   { name: 'Naranja',      color: '#FFA500', id: 'orange'      },
  //   { name: 'Amarilla',     color: '#FFFF00', id: 'yellow'      },
  //   { name: 'Verde Pastel', color: '#BDECB6', id: 'light-green' },
  //   { name: 'Rojo',         color: '#FF6961', id: 'red'         },
  // ]

  // _hardCovers = [
  //   { name:'Transparente',            color: '#FFFFFF', id: 'transparent' /*, defaultFront: true */ },
  //   { name:'Negro (Opaca)',           color: '#000000', id: 'black' /*,       defaultBack: true */ },
  //   { name:'Turquesa',                color: '#40E0D0', id: 'turquoise'   },
  //   { name:'Rosa Pastel (Opaca)',     color: '#FFC0CB', id: 'light-pink'  },
  //   { name:'Lila Pastel (Opaca)',     color: '#9370DB', id: 'lilac'       },
  //   { name:'Azul Pastel (Opaca)',     color: '#87CEFA', id: 'light-blue'  },
  //   { name:'Naranja Pastel (Opaca)',  color: '#FFA500', id: 'orange'      },
  //   { name:'Amarillo Pastel (Opaca)', color: '#FFFF00', id: 'yellow'      },
  //   // { name:'Verde Pastel (Opaca)',    color: '#BDECB6', id: 'light-green' },
  //   // no red
  // ]

  private printSettings = { // OJO las opciones se manejan (index+1). selected =0 (no hay ningun seleccionado)
    skipFitToPage: {
      title: 'Es una programación didáctica',
      selected: 2,
      options: [
        {
          title: 'Sí',
          subTitle: null,
          // subTitle:'290 x 270 mm',
          size: '6',
          value: true,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1, 3],
              subTitle: 'Tamaño incompatible'
            },
            {
              id: 'pagesPerSheet',
              options: [2, 3],
              subTitle: 'Sólo a 1 pág. por cara'
            },
          ]
        },
        {
          title: 'No',
          subTitle: null,
          // subTitle:'290 x 270 mm',
          size: '6',
          value: false
        }
      ]
    },
    pageSize: {
      title: 'Tamaño del papel',
      selected: 2,
      options: [
        {
          title: 'A5',
          subTitle: '14.8x21cm',
          // subTitle:'290 x 270 mm',
          size: '4',
          value: PageSize.A5,
          unavailableFor: [
            {
              id: 'skipFitToPage',
              options: [1],
              subTitle: 'Desact. Programación'
            },
            {
              id: 'finishing',
              options: [4, 6, 7],
              subTitle: 'Acabado incompatible'
            },
            {
              id: 'coverLaminated',
              options: [2],
              subTitle: 'Desact. Portada plastificada'
            },
          ]
        },
        {
          title: 'A4',
          subTitle: '21x29.7cm',
          // subTitle:'290 x 270 mm',
          size: '4',
          value: PageSize.A4
        },
        {
          title: 'A3',
          subTitle: '29.7x42cm',
          // subTitle:'420 x 297 mm',
          size: '4',
          value: PageSize.A3,
          unavailableFor: [
            {
              id: 'skipFitToPage',
              options: [1],
              subTitle: 'Desact. Programación'
            },
            {
              id: 'finishing',
              options: [2, 3, 4, 6, 7],
              subTitle: 'Acabado incompatible'
            },
          ]
        }
      ]
    },
    paperWeight: {
      title: 'Grosor del papel',
      selected: 1,
      options: [
        {
          title: '80 gr',
          // title:'80 g/m2',
          subTitle: 'Un folio de toda la vida',
          size: '4',
          value: PaperWeight.W80,
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '90 gr',
          // title:'100 g/m2',
          subTitle: 'Un poco más grueso',
          size: '4',
          value: PaperWeight.W90,
          unavailableFor: [
            {
              id: 'finishing',
              options: [5],
              subTitle: 'Acabado incompatible'
            }
          ],
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '100 gr',
          // title:'100 g/m2',
          subTitle: 'Un poco más grueso aún',
          size: '4',
          value: PaperWeight.W100,
          unavailableFor: [
            {
              id: 'finishing',
              options: [5],
              subTitle: 'Acabado incompatible'
            }
          ],
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '120 gr',
          // title:'160 g/m2',
          subTitle: 'Aún más grueso',
          size: '4',
          value: PaperWeight.W120,
          unavailableFor: [
            {
              id: "pageSize",
              options: [3],
              subTitle: 'Tamaño no válido'
            },
            {
              id: 'finishing',
              options: [5],
              subTitle: 'Acabado incompatible'
            }
          ],
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '160 gr',
          // title:'160 g/m2',
          subTitle: 'Ni fino ni grueso',
          size: '4',
          value: PaperWeight.W160,
          unavailableFor: [
            {
              id: 'finishing',
              options: [5],
              subTitle: 'Acabado incompatible'
            }
          ]
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '200 gr',
          // title:'200 g/m2',
          subTitle: 'Tipo cartulina fina',
          size: '4',
          value: PaperWeight.W200,
          unavailableFor: [
            {
              id: "pageSize",
              options: [1, 2],
              subTitle: 'Tamaño no válido'
            },
            {
              id: 'finishing',
              options: [5],
              subTitle: 'Acabado incompatible'
            }
          ],
          // hideSubtitleFor:[
          //   {
          //     id:"pageSize",
          //     options:[1]
          //   }
          // ]
        },
        {
          title: '300 gr',
          // title:'100 g/m2',
          subTitle: 'Tipo cartulina del cole',
          size: '12',
          value: PaperWeight.W300,
          unavailableFor: [
            {
              id: 'finishing',
              options: [2, 3, 4, 5, 6, 7],
              subTitle: 'Acabado incompatible'
            },
          ]
        }
      ]
    },
    color: {
      title: 'Color de impresión',
      selected: 1,
      options: [
        {
          title: 'Blanco y negro',
          subTitle: 'Escala de grises',
          // title:'BYN',
          // subTitle:'Blanco y Negro',
          size: '6',
          value: PrintColor.BLACKNWHITE
        },
        {
          title: 'Color',
          subTitle: "Formato CMYK",
          // subTitle:'Colores CMYK',
          size: '6',
          value: PrintColor.COLOR
        }
      ]
    },
    // grosor:{
    //   title:"Grosor del Papel",
    //   selected:1,
    //   options:[
    //     {
    //       title:"80GR.",
    //       subTitle:"Papel Copistería",
    //       size:"4"
    //     },
    //     {
    //       title:"160GR.",
    //       subTitle:"Grueso Alto",
    //       size:"4",
    //       unavailableFor:[
    //         {
    //           id:"tamano",
    //           options:[2,3]
    //         }
    //       ]
    //     },
    //     {
    //       title:"280GR.",
    //       subTitle:"Tipo Cartulina",
    //       size:"4",
    //       unavailableFor:[
    //         {
    //           id:"tamano",
    //           options:[2,3]
    //         }
    //       ]
    //     },
    //   ]
    // },
    twoSided: {
      title: 'Forma de impresión',
      selected: 2,
      key: 'twoSided',
      options: [
        {
          title: 'A una cara',
          subTitle: 'Impreso por una cara del papel',
          size: '6',
          value: false
        },
        {
          title: 'A doble cara',
          subTitle: 'Impreso por delante y por detrás del folio',
          size: '6',
          value: true
        },
      ]
    },
    autoRotate: {
      title: 'Rotación automática',
      selected: 1,
      key: 'autoRotate',
      options: [
        {
          title: 'AutoRotación',
          subTitle: 'Automática',
          size: '6',
          value: true
        },
        {
          title: 'Sin AutoRotación',
          subTitle: 'Automática',
          size: '6',
          value: false
        },
      ]
    },
    pagesPerSheet: {
      title: 'Páginas por cara',
      selected: 1,
      options: [
        {
          title: '1 Página por cara',
          subTitle: null,
          size: '12',
          image: 'assets/icon/1pagePerSheet.svg',
          value: PagesPerSheet.ONE
        },
        {
          title: '2 Páginas por cara',
          subTitle: null,
          size: '12',
          image: 'assets/icon/2pagePerSheet.svg',
          value: PagesPerSheet.TWO,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1]
            },
            {
              id: 'skipFitToPage',
              options: [1],
              subTitle: 'Desact. Programación'
            }
          ]
        },
        {
          title: '4 Páginas por cara',
          subTitle: null,
          size: '12',
          image: 'assets/icon/4pagePerSheet.svg',
          value: PagesPerSheet.FOUR,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1]
            },
            {
              id: 'skipFitToPage',
              options: [1],
              subTitle: 'Desact. Programación'
            }
          ]
        },
      ]
    },
    pageOrientation: {
      title: 'Orientación del folio',
      selected: 1,
      options: [
        {
          title: 'Vertical',
          subTitle: null,
          size: '6',
          value: PageOrientation.PORTRAIT
        },
        {
          title: 'Horizontal',
          subTitle: null,
          size: '6',
          value: PageOrientation.LANDSCAPE
        },
      ]
    },
    pageTurn: {
      title: '¿Cómo quieres pasar las páginas?',
      selected: 1,
      tip: 'InfoPasarPaginaComponent',
      options: [
        {
          title: null,
          subTitle: null,
          size: '6',
          lottie: 'assets/lotties/vertical-largo.json',
          value: PageTurn.LARGESIDE
        },
        {
          title: null,
          subTitle: null,
          size: '6',
          lottie: 'assets/lotties/vertical-corto.json',
          value: PageTurn.SHORTSIDE,
          unavailableFor: [
            {
              id: 'finishing',
              options: [6, 7],
              subTitle: 'Incompatible con perforado'
            }
          ]
        },
      ]
    },
    finishing: {
      title: null,
      selected: 1,
      options: [
        {
          title: 'Sin acabado',
          subTitle: 'Hojas sueltas',
          size: '6',
          image: 'assets/images/finishings/sinAcabado.svg',
          value: Finishing.NONE
        },
        {
          title: 'Encuadernado',
          subTitle: 'Espiral y tapas',
          size: '6',
          image: 'assets/images/finishings/encuadernado.svg',
          value: Finishing.BINDING,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [3]
            },
            {
              id: 'paperWeight',
              options: [7],
              subTitle: 'Gramaje incompatible'
            }
          ]
        },
        {
          title: 'Encuadernado con portada plastificada',
          subTitle: 'En espiral y con portada plastificada',
          size: '12',
          image: 'assets/images/finishings/encuadernadoPortadaPlastificada.svg',
          value: Finishing.BINDING,
          extraData: { isBindingWithCoverLaminated: true },
          unavailableFor: [
            {
              id: 'pageSize',
              options: [3]
            },
            {
              id: 'paperWeight',
              options: [7],
              subTitle: 'Gramaje incompatible'
            }
          ]
        },
        {
          title: 'Grapado',
          subTitle: 'Esquina grapada',
          size: '6',
          image: 'assets/images/finishings/grapado.svg',
          value: Finishing.STAPLED,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1, 3]
            },
            {
              id: 'paperWeight',
              options: [7],
              subTitle: 'Gramaje incompatible'
            }
          ]
        },
        {
          title: 'Plastificado',
          subTitle: 'Hoja plastificada',
          size: '6',
          image: 'assets/images/finishings/plastificado.svg',
          value: Finishing.LAMINATED,
          unavailableFor: [
            {
              id: 'paperWeight',
              options: [],
              subTitle: 'Gramaje incompatible'
            }
          ]
        },
        {
          title: 'Perforado 2',
          subTitle: '2 agujeros',
          size: '6',
          image: 'assets/images/finishings/dosAgujeros.svg',
          value: Finishing.PERFORATED2,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1, 3],
              subTitle: 'Tamaño incompatible'
            },
            {
              id: 'pageTurn',
              options: [2],
              subTitle: 'Solo borde largo'
            },
          ]
        },
        {
          title: 'Perforado 4',
          subTitle: '4 agujeros',
          size: '6',
          image: 'assets/images/finishings/cuatroAgujeros.svg',
          value: Finishing.PERFORATED4,
          unavailableFor: [
            {
              id: 'pageSize',
              options: [1, 3],
              subTitle: 'Tamaño incompatible'
            },
            {
              id: 'pageTurn',
              options: [2],
              subTitle: 'Solo borde largo'
            },
          ]
        },
        // {
        //   title: 'TALADRADO',
        //   subTitle: 'para encuadernación',
        //   size: '6',
        //   value: Finishing.PERFORATEDBIND,
        //   unavailableFor: [
        //     {
        //       id: 'paperWeight',
        //       options: [3],
        //       subTitle: 'Gramaje incompatible'
        //     },
        //     {
        //       id: 'pageSize',
        //       options: [3],
        //       subTitle: 'Tamaño incompatible'
        //     }
        //   ]
        // },
      ]
    },
    grouped: {
      title: null,
      selected: 2,
      options: [
        {
          title: 'Individualmente',
          subTitle: 'Por separado',
          size: '5.8',
          value: false
        },
        {
          title: 'Agrupado',
          subTitle: 'Todo junto',
          size: '5.8',
          value: true
        },
      ]
    },
    coverColor: {
      title: 'Imprimir portadas a color',
      selected: 1,
      options: [
        {
          title: 'Imprimir portadas a color',
          value: false
        },
        {
          title: 'Imprimir portadas a color',
          value: true
        }
      ]
    },
    coverLaminated: {
      title: 'Portada plastificada a color',
      selected: 1,
      options: [
        {
          title: 'Portada plastificada a color',
          value: false
        },
        {
          title: 'Portada plastificada a color',
          value: true
        }
      ]
    },
    ringColor: {
      title: 'Color de las anillas',
      selected: 1,
      options: [
        // {size:1, value:{name:'Transparente', color: '#FFFFFF'}},
        // {size:1, value:{name:'Negro', color: '#000000'}},
        // {size:1, value:{name:'Turquesa', color: '#40E0D0'}},
        // {size:1, value:{name:'Rosa Pastel', color: '#FFC0CB'}},
        // {size:1, value:{name:'Lila', color: '#9370DB'}},
        // {size:1, value:{name:'Azul Pastel', color: '#87CEFA'}},
        // {size:1, value:{name:'Naranja', color: '#FFA500'}},
        // {size:1, value:{name:'Amarillo', color: '#FFFF00'}}
      ]
    },
    hardCoverFront: {
      title: 'Tapa delantera',
      selected: 1,
      options: []
    },
    hardCoverBack: {
      title: 'Tapa trasera',
      selected: 2,
      options: []
    },
    laminationType: {
      title: 'Tipo de plastificado',
      selected: 1,
      options: [
        {
          title: 'Mate',
          value: LaminationType.Matte,
          size: 6,
          video: "/assets/videos/plastificado-mate.mp4"
        },
        {
          title: 'Brillo',
          value: LaminationType.Gloss,
          size: 6,
          video: "/assets/videos/plastificado-brillo.mp4"
        },
      ]
    },
    laminationWeight: {
      title: 'Micraje del plastificado',
      selected: 1,
      options: [
        {
          title: '80',
          value: LaminationWeight.W80,
          size: 6,
          unavailableFor: [
            {
              id: 'laminationType',
              options: [1],
            },
            {
              id: 'finishing',
              options: [3],
            }
          ]
        },
        {
          title: '125',
          value: LaminationWeight.W125,
          size: 6,
        },
        {
          title: '150',
          value: LaminationWeight.W150,
          size: 6,
          unavailableFor: [
            {
              id: 'laminationType',
              options: [2],
            }
          ]
        },
        {
          title: '175',
          value: LaminationWeight.W175,
          size: 6,
          unavailableFor: [
            {
              id: 'laminationType',
              options: [1],
            },
            {
              id: 'finishing',
              options: [3],
            }
          ]
        },
        {
          title: '250',
          value: LaminationWeight.W250,
          size: 6
        },
      ]
    },
    docColor: {
      title: 'Imprimir todo a color',
      selected: 1,
      options: [
        {
          title: 'Imprimir todo a color',
          value: false
        },
        {
          title: 'Imprimir todo a color',
          value: true
        }
      ]
    }
  };

  private printPresets = [
    {
      name: 'Por defecto',
      default: true
    },
    {
      name: 'Preset 1',
      autoRotate: 2,
      twoSided: 1
    },
    {
      name: 'Apuntes'
    },
    {
      name: 'Diplomas'
    }
  ];


  constructor(
    private db: AngularFirestore,
    private settingService: SettingService,
    private http: HttpClient,
    public platform: Platform,
    private modalController: ModalController
  ) {


    this.setIsMobile(this.platform.width());

    this.db.firestore.collection('settings').doc('bankInformation').get()
      .then(snap => {
        this.bankInformation = snap.data() as BankInformation;
      });
    this.settingService.getGeneral()
      .subscribe(generalSetting => {
        this._generalSettings.next(generalSetting)
        this.checkOrdersBlocked(generalSetting.threshold.blocked, generalSetting.ordersBlockedTitle, generalSetting.ordersBlockedBody)
      })

    this.settingService.getBanners()
      .subscribe(appBanners => {
        this.appBanners = appBanners;
        this.isInit.next(true);
      });


    this.platform.resize.subscribe(async () => {
      this.setIsMobile(this.platform.width());
    });
  }

  setRingsCoversSettings(ringColors, hardCovers) {
    this._ringColors = [...ringColors];
    //Ajustamos PrintSettings
    this.printSettings.ringColor.options = ringColors.map(ringColor => ({ size: 1, value: { ...ringColor } }));
    this.printSettings.hardCoverFront.options = hardCovers.map(hardCover => ({ size: 1, value: { ...hardCover } }));
    this.printSettings.hardCoverBack.options = hardCovers.map(hardCover => ({ size: 1, value: { ...hardCover } }));
  }

  setIsMobile(width) {
    this.isMobile = width < 1104;
  }

  getPrintSettings() {
    return cloneDeep(this.printSettings);
  }

  getPrintSettingDefault(setting: Settings) {
    const index = this.printSettings[setting].selected - 1;
    return this.printSettings[setting].options[index].value;
  }

  getPrintPresets() {
    return cloneDeep(this.printPresets);
  }


  getIpInfo() {
    // const url = `http://www.ip-api.com/json`;
    ///ipinfo/
    const url = `${environment.apiUrl}ipinfo`;
    if (auth().currentUser) {
      return auth().currentUser.getIdToken(/* forceRefresh */ false).then(idToken => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${idToken}`,
          'project-id': `${environment.firebaseConfig.projectId}`
        });
        const body = {};
        return this.http.request('POST', url, { headers, body }).toPromise();
      });
    } else {
      return Promise.reject(new Error('fail-invalid currentUser'));
    }
  }

  // getIpInfo(){
  //   // const url = `http://www.ip-api.com/json`;
  //   ///ipinfo/
  //   const url = `https://api64.ipify.org/?format=json`;
  //   return this.http.get(url)
  //   .toPromise()
  //   .then((res:any)=>{
  //     return res;
  //   })
  // }

  /**
   * Funciones de upgrade de products
   */
  upgradeCart(cart: ShoppingCart) {
    if (cart && cart.items) {
      cart.items.map(item => {
        this.upgradePrintProduct(item.product as Product);
        return item;
      });
    }
  }

  upgradePrintProduct(product: Product) {
    if (product.type === ProductType.PRINT) {
      product.printingGroup.printSettings.paperWeight = product.printingGroup.printSettings.paperWeight ?? PaperWeight.W80;
      this.upgradeRingColor(product);
      this.upgradeHardCover(product);
      product.printingGroup.files.map(file => {
        delete file['thumbFiles'];
        delete file['tooks'];
        return file;
      });
    }
    return product;
  }

  upgradeRingColor(product: Product) {
    if (product.printingGroup.printSettings.ringColor && !product.printingGroup.printSettings.ringColor.id) {
      product.printingGroup.printSettings.ringColor = this._ringColors.find(ringColor => product.printingGroup.printSettings.ringColor.name === ringColor.name);
    }
    if (!product.printingGroup.printSettings.ringColor) {
      product.printingGroup.printSettings.ringColor = this.getPrintSettingDefault(Settings.RING_COLOR);
    }
  }

  upgradeHardCover(product: Product) {
    //Si no tiene hardCover se le agregan
    if (!product.printingGroup.printSettings.hardCoverFront) {
      product.printingGroup.printSettings.hardCoverFront = this.getPrintSettingDefault(Settings.HARD_COVER_FRONT);
      product.printingGroup.printSettings.hardCoverBack = this.getPrintSettingDefault(Settings.HARD_COVER_BACK);
    }
  }

  private async checkOrdersBlocked(ordersBlocked: boolean, title, body) {
    if (ordersBlocked && !this.isOrdersBlocked) {
      const modal = await this.modalController.create({
        component: OrdersBlockedAlertComponent,
        cssClass: "auto-height ",
        backdropDismiss: false,
      });
      modal.present();
    }
    this.isOrdersBlocked = ordersBlocked;
    this.ordersBlockedTitle = title;
    this.ordersBlockedBody = body;
  }


}
