import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ShippingSettings, ShippingType } from 'src/app/models/shipping-settings.model';
import { SettingService } from '../setting.service';
import { PickupLocationService } from "../pickup-location.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
import { addDays, format, isWeekend, parseISO, startOfDay } from "date-fns";
import { es } from 'date-fns/locale';
import { Timestamp } from "@angular/fire/firestore";


@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private isInit = new BehaviorSubject<boolean>(false);
  public isInit$ = this.isInit.asObservable();
  freeWeightLimit;
  weightPrices;
  shippingPriceRanges;
  zoneWeightPrices;
  zoneShippingInfo;
  zoneFreeCases;
  shippingSettings: ShippingSettings;

  hourToChange = 14
  minuteToChange = 0

  constructor(
    private settingService: SettingService,
    private pickupLocationService: PickupLocationService,
    private db: AngularFirestore
  ) {
    this.settingService.getShippingSettings()
      .then(shippingSettings => {
        this.shippingSettings = shippingSettings;
        this.freeWeightLimit = shippingSettings.freeWeightLimit;
        this.shippingPriceRanges = shippingSettings.priceRanges;
        this.weightPrices = shippingSettings.weightPrices;
        this.zoneWeightPrices = shippingSettings.zoneWeightPrices;
        this.zoneShippingInfo = shippingSettings.zoneShippingInfo;
        this.zoneFreeCases = shippingSettings.zoneFreeCases;
        this.isInit.next(true);
      });
  }

  getFreeShippingPrice(_weight, postalcode) {
    const weight = _weight / 1000;
    const cp = postalcode ? postalcode.substring(0, 2) : null;
    let weightPrices = this.getWeightPrice(cp);

    const dif = weight - this.freeWeightLimit;

    return dif > 0
      ? weightPrices.extra * Math.ceil(dif)
      : 0;
  }

  getWeightShippingPrice(_weight, postalcode) {
    const cp = postalcode ? postalcode.substring(0, 2) : null;
    let weightPrices = this.getWeightPrice(cp);
    //Pasamos el peso a kilos para  calcular el precio
    const weight = _weight / 1000;
    if (!weight) {
      return 0;
    }
    const range = weightPrices.range.find(_range => {
      return weight > _range[0] && weight <= _range[1];
    });
    if (range !== undefined) {
      return range[2];
    } else {
      const maxR = weightPrices.range[weightPrices.range.length - 1];
      //Retornamo el precio máximo + la diferencia en kilos por el precio extra por kilos
      return weightPrices.extra * Math.ceil((weight - maxR[1])) + maxR[2];
    }
  }

  getRangeShippingPrice(cartPrice) {
    let priceRange = this.shippingPriceRanges.find(range => {
      return cartPrice >= range.minLimit && cartPrice <= range.maxLimit;
    });
    if (!priceRange) {
      const roundedValue = Math.round((cartPrice + Number.EPSILON) * 100) / 100;
      priceRange = this.shippingPriceRanges.find(range => roundedValue >= range.minLimit && roundedValue <= range.maxLimit);
    }
    return priceRange ? priceRange.price : this.shippingPriceRanges[0].price;
  }

  getWeightPrice(cp) {
    return this.zoneWeightPrices.find(zwp => zwp.zone.includes(cp)) ?? this.weightPrices;
  }


  shippingInfo(postalcode) {
    var info = null;
    for (let caseZone of this.zoneShippingInfo) {
      if (caseZone.zone.length == 0 || this.isMatchZone(postalcode, caseZone.zone)) {
        info = caseZone.info;
        break;
      }
    }
    return info;
  }

  freeShipping(postalcode) {
    var amount = 0; // 0 means: no free shipping
    // We need to know if there is freeShipping already in the CartPreview, where there isn't any postalcode provided yet. So
    // it is set a default value of 35 until a postalcode value exists.
    /*if (!postalcode) {
      return 35;
    }*/
    for (let caseZone of this.zoneFreeCases) {
      if (caseZone.zone.length == 0 || this.isMatchZone(postalcode, caseZone.zone)) {
        amount = caseZone.amount;
        break;
      }
    }
    return amount;
  }

  isMatchZone(postalcode, zone) {
    var res = false;
    for (let zc of zone) {
      if (postalcode?.startsWith(zc)) {
        res = true;
        break;
      }
    }
    return res;
  }

  isShippingTypeAvailable(shippingType: ShippingType, postalCode = '', pickupPointCode = null) {
    return this.shippingSettings.available.includes(shippingType) && !this.shippingTypeHiddenForProvince(shippingType, postalCode)
      && !this.shippingTypeDisabledForProvince(shippingType, postalCode);
  }


  shippingTypeDisabled(shippingType, shippingAddress, onlyWalletBonus) {
    const { postalCode } = shippingAddress;
    const shippingDisabledForProvince = this.shippingTypeDisabledForProvince(shippingType, postalCode);

    return shippingDisabledForProvince || this.shippingTypeDisabledForPoint(shippingType, shippingAddress) || onlyWalletBonus;
  }

  shippingTypeDisabledForPoint(shippingType, shippingAddress) {
    const { addressLine1, pickupPointCode, shippingCourier } = shippingAddress;
    const availableCouriersForPoints = this.pickupLocationService.availableCouriers;
    return (
      addressLine1 !== '' &&
      !!pickupPointCode &&
      availableCouriersForPoints.hasOwnProperty(shippingCourier) &&
      !availableCouriersForPoints[shippingCourier]?.shippingTypes[shippingType]
    );
  }

  shippingTypeDisabledForProvince(shippingType, postalCode) {
    const provinceId = postalCode ? postalCode.substring(0, 2) : null;
    const shippingRestrictions = this.shippingSettings.shippingTypeRestrictions[shippingType];
    return shippingRestrictions && shippingRestrictions.some(r => r.provinceIds.includes(provinceId) && r.show);
  }

  shippingTypeHiddenForProvince(shippingType, postalCode) {
    const provinceId = postalCode ? postalCode.substring(0, 2) : null;
    const shippingRestrictions = this.shippingSettings.shippingTypeRestrictions[shippingType];
    return (shippingRestrictions && shippingRestrictions.some(r => r.provinceIds.includes(provinceId) && !r.show)) || !this.shippingSettings.available.includes(shippingType);
  }

  getHolidays(year: number): Observable<{ date: Timestamp; postalCodes: string[] }[]> {
    return this.db.collection('holidays').doc(`${year}`).valueChanges().pipe(
      map((data: any) => data?.holidays ?? [])
    );
  }

  getShippingDeliveryDate(shippingType: ShippingType, shippingAddress = null): Observable<{
    minDeliveryDate: Date,
    maxDeliveryDate: Date,
    textToShow: string
  }> {
    const now = new Date();

    // Obtener los festivos del año actual y del siguiente desde Firestore
    return combineLatest([
      this.getHolidays(now.getFullYear()),
      this.getHolidays(now.getFullYear() + 1)
    ]).pipe(
      map(([currentYearHolidays, nextYearHolidays]: [{ date: Timestamp; postalCodes: string[] }[], {
        date: Timestamp;
        postalCodes: string[]
      }[]]) => {
        const holidays = [...currentYearHolidays, ...nextYearHolidays]; // Combinar festivos de ambos años
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        let result = '';
        // Establecemos el rango de días según el tipo de envío
        let minDays = 1;
        let maxDays = 1;

        switch (shippingType) {
          case ShippingType.ECONOMIC:
            minDays = 1;
            maxDays = 3;
            break;
          case ShippingType.STANDARD:
            minDays = 1;
            maxDays = 2; // TEMP: temporada alta: 3. Regular: 2
            break;
          case ShippingType.STANDARD_24:
            minDays = 1;
            maxDays = 1; // TEMP: temporada alta: 2. Regular: 1
            break;
          case ShippingType.URGENT:
            minDays = 1;
            maxDays = 1;
            break;
        }

        if (this.isHolidayOrWeekend(now, holidays, shippingAddress.provinceId)) {
          minDays += 1;
          maxDays += 1;
        } else if (currentHour > this.hourToChange || (currentHour === this.hourToChange && currentMinutes >= this.minuteToChange)) {
          // beware: do not add +1 twice if we're on weekend && > 14h
          minDays += 1;
          maxDays += 1;
        }

        if (["07", "35", "38"].includes(shippingAddress?.provinceId)) {
          minDays += 1;
          maxDays += 1;
        }

        //TODO opcion en base de datos para activar un check en admin y dar un dia mas de plazo para el envío estándar

        // Calcular la fecha mínima y máxima de entrega
        let minDeliveryDate = now;
        let maxDeliveryDate = now;
        while (this.isHolidayOrWeekend(minDeliveryDate, holidays, shippingAddress.provinceId) || minDays > 0) {
          minDeliveryDate = addDays(minDeliveryDate, 1);
          if (minDays > 0 && !this.isHolidayOrWeekend(minDeliveryDate, holidays, shippingAddress.provinceId)) {
            minDays--
          }
        }

        while (this.isHolidayOrWeekend(maxDeliveryDate, holidays, shippingAddress.provinceId) || maxDays > 0) {
          maxDeliveryDate = addDays(maxDeliveryDate, 1);
          if (maxDays > 0 && !this.isHolidayOrWeekend(maxDeliveryDate, holidays, shippingAddress.provinceId)) {
            maxDays--
          }
        }
        // Generar el mensaje en función del tipo de envío
        if (minDeliveryDate.getTime() === maxDeliveryDate.getTime()) {
          result = `Llega `;
          if (shippingType === ShippingType.STANDARD_24) {
            result += 'a lo largo del día, '
          }
          if (shippingType === ShippingType.URGENT) {
            result += 'antes de las 14:00, '
          }
          result += `${format(minDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })}`;
        } else {
          result = `Entrega prevista: ${format(minDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })} - ${format(maxDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })}`;
        }

        minDeliveryDate.setHours(7, 0, 0)
        shippingType === ShippingType.URGENT ? maxDeliveryDate.setHours(14, 0, 0) : maxDeliveryDate.setHours(23, 59, 0)
        return {
          minDeliveryDate, maxDeliveryDate, textToShow: result
        }
      })
    );
  }

  private isHolidayOrWeekend(date: Date, holidays: {
    date: Timestamp;
    postalCodes: string[]
  }[], provinceId): boolean {
    // Verificar si el día es fin de semana
    const isWeekendDay = isWeekend(date);
    // Verificar si el día es festivo
    const isHoliday = holidays.some(holiday => {
      const isSameDate = startOfDay(holiday.date.toDate()).getTime() === startOfDay(date).getTime();

      const isPostalCodeMatch = holiday.postalCodes
        ? holiday.postalCodes.includes(provinceId)
        : true;
      return isSameDate && isPostalCodeMatch;
    });

    return isWeekendDay || isHoliday;
  }

}

function objToArr(range: any) {
  throw new Error('Function not implemented.');
}

