import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { Product } from 'src/app/models/product.model';
import { map, takeUntil } from "rxjs/operators";
import { MediaFile } from "../../../shared/a2-files/media.model";
import { FileDbService } from "../../../shared/a2-files/services/file-db.service";
import { ProductBlService } from "../../../shared/services/productBl.service";
import { firstValueFrom, Subject } from 'rxjs';

@Component({
  selector: 'app-product-variants',
  templateUrl: './product-variants.component.html',
  styleUrls: ['./product-variants.component.scss']
})
export class ProductVariantsComponent implements OnInit {
  @Input() options: Array<Product.Option>;
  @Input() variants: Array<Product.Variant>;
  @Input() selected: Product.Variant;
  @Input() product;
  @Input() galleries;
  @Input() isProductCard: boolean = false;
  @Input() backSubject: Subject<void>;
  private destroy$ = new Subject<void>();
  @Output() changes = new EventEmitter<any>();
  optionsSelected: Array<String> = []
  _variantSelected: Product.Variant = null;
  available: Array<any> = [];

  galleryImages$;

  step: number = 0;

  constructor(private fileDbService: FileDbService, private productBlService: ProductBlService) {
  }

  ngOnInit() {
    this.backSubject?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      //this.optionsSelected.pop();
      --this.step;
    })

    this.galleryImages$ = this.fileDbService.getAll$(`products/${this.product.id}/files`).pipe(
      map(files => {
        this.galleries = {};
        const addGallery = (name, fileIds) => {
          this.galleries[name] = files
            .filter(file => fileIds.includes(file.id))
            .sort((a, b) => fileIds.indexOf(a.id) - fileIds.indexOf(b.id))
            .map(mediaFile => ({
              small: MediaFile.addNameSuffix(mediaFile.url, 's'),
              medium: MediaFile.addNameSuffix(mediaFile.url, 'm'),
              big: MediaFile.addNameSuffix(mediaFile.url, 'l'),
            }));
        };
        addGallery('Principal', this.product.mediaFiles);
        const variantGalleries = this.product.options[0]?.galleries;
        variantGalleries?.forEach(gal => {
          addGallery(gal.name, gal.gallery);
        });
        return this.galleries;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      const selected = changes.selected.currentValue;
      if (selected) {
        this.optionsSelected = [...selected.optionsValue]
      }
      if (changes.selected.isFirstChange()) {
        this.setAvailableOptions(0);
      }
    }
  }

  onChanges(i, event) {
    this.optionsSelected[i] = event.option;
    this.setSelected();
    this.setAvailableOptions(i);
  }

  nextStep() {
    if (this.step < this.options.length) {
      ++this.step;
      this.changes.emit({ step: this.step });
    }
  }

  setSelected() {
    this._variantSelected = { ...this.variants.find(variant => isEqual(variant.optionsValue, this.optionsSelected)) }
    this.changes.emit({ variant: this._variantSelected });
  }


  //TODO mejorar la lógica de disponibilidad
  setAvailableOptions(optionIndex) {
    // const availableVariants = this.variants.filter(variant => variant.optionsValue[optionIndex] === this.optionsSelected[optionIndex]);
    // this.available = this.options.map((_, i)=>{
    //   return availableVariants.reduce((acc, variant)=>{
    //     return acc.includes(variant.optionsValue[i]) ? [...acc] : [...acc, variant.optionsValue[i]]
    //   }, new Array() as Array<String>)
    // })
    this.available = this.options.map((_, i) => {
      return this.variants.reduce((acc, variant) => {
        return acc.includes(variant.optionsValue[i]) ? [...acc] : [...acc, variant.optionsValue[i]]
      }, new Array() as Array<String>)
    })

  }

  selectDefaultVariant() {
    this._variantSelected = null
    for (const variant of this.variants) {
      if ((variant.allowSaleNoStock || variant.stock > 0) && !this.isProductCard && (variant.visible !== false)) {
        this.optionsSelected = [...variant.optionsValue];
        this.setSelected();
        break;
      }
    }
    if (!this._variantSelected) {
      this.optionsSelected = [...this.variants.find(v => v.visible !== false).optionsValue];
      this.setSelected();
    }
  }
}
