import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { OrderProcessStatus, ShippingInternalStatus, TrackingData } from "../../models/models";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { AlertController, ToastController } from "@ionic/angular";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'app-client-tracking-data',
  templateUrl: './client-tracking-data.component.html',
  styleUrls: ['./client-tracking-data.component.scss'],
})
export class ClientTrackingDataComponent implements OnInit, OnChanges {
  @Input() trackingData: TrackingData
  @Input() order = null
  @Input() showTrackingLink = true;

  @Output() trackingRefreshed = new EventEmitter<boolean>()
  API_REPORT_DELAY = 'api2/orders/reportShippingDelay';

  error = false;
  isVertical: boolean = false;
  verticalBreakPoint = 680;
  private resizeObserver: ResizeObserver;
  fillPercentage = 20
  statusAnnotation = '';
  markers = {
    copyfly: {
      text: 'En copyfly',
      image: 'assets/icon/tracking-plane.svg',
      position: 0
    },
    onDelivery: {
      text: 'En proceso de entrega',
      image: 'assets/icon/tracking-on-delivery.svg',
      position: 55,
    },
    delivered: {
      text: 'Entrega prevista:',
      text2: '',
      image: 'assets/icon/tracking-delivered.svg',
      position: 100,
    },
    truck: {
      class: 'truck',
      image: 'assets/icon/tracking-truck.svg',
      position: 20
    }
  };
  specialMessage = null;
  showData = true;
  isDelayed = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private alertController: AlertController, private functions: AngularFireFunctions,
              private toastController: ToastController) {
  }

  ngOnInit() {
    this.refreshData()
  }

  ngAfterViewInit(): void {
    const observedElement = this.el.nativeElement.querySelector('.tracking-container'); // Observa un subelemento
    if (observedElement) {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          this.isVertical = width < this.verticalBreakPoint;
          this.updateClass();
        }
      });
      this.resizeObserver.observe(observedElement);
    }
  }

  private updateClass(): void {
    const element = this.el.nativeElement;
    this.renderer.removeClass(element, 'vertical');
    this.renderer.removeClass(element, 'horizontal');
    this.renderer.addClass(element, this.isVertical ? 'vertical' : 'horizontal');
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trackingData) {
      this.refreshData()
    }
  }

  getMarkersArray() {
    return Object.values(this.markers);
  }

  private refreshData() {
    this.showData = true;
    if (this.order.shippingCourierService === 'local') {
      this.specialMessage = 'Tu pedido ha sido enviado y lo recibirás pronto.'
    }
    const currentDate = new Date();
    this.isDelayed = this.trackingData.internalStatus !== ShippingInternalStatus.DELIVERED && currentDate > this.trackingData.maxDeliveryDate
    const processing = [OrderProcessStatus.PROCESSING, OrderProcessStatus.PENDING].includes(this.order.processStatus)
    try {
      if (this.trackingData.minDeliveryDate && this.trackingData.maxDeliveryDate) {
        this.markers.delivered.text2 =
          this.trackingData.minDeliveryDate.toISOString().slice(0, 10) === this.trackingData.maxDeliveryDate.toISOString().slice(0, 10) ? `${format(this.trackingData.minDeliveryDate, "EEEE',' dd/MM", { locale: es })}`
            : `${format(this.trackingData.minDeliveryDate, "EEEE',' dd/MM", { locale: es })}
       - ${format(this.trackingData.maxDeliveryDate, "EEEE',' dd/MM", { locale: es })}`
      }
      switch (this.order.processStatus) {
        case OrderProcessStatus.PROCESSING:
        case OrderProcessStatus.PENDING:
        case OrderProcessStatus.INCIDENCE:
        case OrderProcessStatus.PAUSED:
        case OrderProcessStatus.ONHOLD:
          this.statusAnnotation = 'Estamos preparando tu pedido'
          this.fillPercentage = 0;
          this.markers.truck.class = 'truck hidden';
          break;
        case OrderProcessStatus.COMPLETE:
          this.statusAnnotation = 'Pedido preparado. Pte. de recoger'
          this.fillPercentage = 1;
          this.markers.truck.position = 15;
          break;

        case OrderProcessStatus.CANCELED:
        case OrderProcessStatus.RETURNED:
          this.showData = false;
          break;
        case OrderProcessStatus.SHIPPED:
          switch (this.trackingData.internalStatus) {
            case ShippingInternalStatus.PICKUP:
            case ShippingInternalStatus.INPROGRESS:
              this.statusAnnotation = 'En camino. El paquete ha salido de las instalaciones y se dirige a tu destino'
              this.fillPercentage = 35;
              this.markers.truck.position = 35;
              this.markers.truck.class = 'truck flipped'
              break;
            case ShippingInternalStatus.RETURNED:
              this.specialMessage = 'Tu pedido ha sido devuelto al origen.'
              break;
            case ShippingInternalStatus.ONDELIVERY:
            case ShippingInternalStatus.TRIED:
              this.statusAnnotation = 'En camino. Pronto lo tendrás en casa.'
              this.fillPercentage = 70;
              this.markers.truck.position = 70;
              this.markers.truck.class = 'truck flipped'
              break;
            case ShippingInternalStatus.DELIVERED:
              this.statusAnnotation = !!this.order.shippingAddress?.pickupPointCode ? 'Disponible en punto de recogida' : 'Entregado'
              this.fillPercentage = 101;
              this.markers.truck.class = 'truck hidden'
              this.markers.delivered.text = !!this.order.shippingAddress?.pickupPointCode ? 'En punto de recogida' : 'Entregado'
              this.markers.delivered.text2 = ''
              break;
            default:
              this.statusAnnotation = 'Estamos preparando tu pedido'
              this.fillPercentage = 0;
              this.markers.truck.class = 'truck hidden';
              break;
          }
      }
      if (processing) {

      } else {

      }
      this.trackingRefreshed.emit(true)
    } catch (e) {
      this.error = true;
      console.log('error', e.message)
    }
  }

  _API_report_delay(data = null) {
    return firstValueFrom(this.functions.httpsCallable(`${this.API_REPORT_DELAY}/${data.order.id}`)(data));
  }

  async reportDelayConfirmation(order: any) {
    const alert = await this.alertController.create({
      header: 'Reportar retraso',
      subHeader: '',
      message: '¿Quieres informar de un retraso en tu pedido?',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      }, {
        text: 'Confirmar',
        role: 'confirm'
      }]
    });
    await alert.present();
    return alert.onDidDismiss().then(res => {
      if (res.role === 'confirm') {
        this.reportDelay(order);
      }
    });
  }

  async reportDelay(order) {
    const resp = await this._API_report_delay({ order })
    if (resp.status === 'ok') {
      const toast = await this.toastController.create({
        message: 'Tu notificación ha sido enviada con exito.',
        color: 'success',
        position: 'middle',
        cssClass: 'fitWidthToast',
        duration: 4000
      });
      this.order.shippingDelayReported = true
      toast.present()
    } else {
      const toast = await this.toastController.create({
        message: 'Ha ocurrido un error',
        color: 'danger',
        position: 'middle',
        cssClass: 'fitWidthToast',
        duration: 4000
      });
      toast.present()
    }
  }
}
