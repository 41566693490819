import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppService } from "../../shared/app.service";
import { ModalController } from "@ionic/angular";
import { FileService } from "../../shared/file.service";
import { Finishing, PageSize, PaperWeight } from "../../models/models";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { VideoPlayerModalComponent } from "../video-player-modal/video-player-modal.component";

@Component({
  selector: 'app-cover-settings',
  templateUrl: './cover-settings.component.html',
  styleUrls: ['./cover-settings.component.scss'],
})
export class CoverSettingsComponent implements OnInit {
  @Input() fileSettings;
  @Input() currentCoverType;
  paperWeight = PaperWeight.W80;
  coverOption = null;
  coverSettings = null;
  videoPath = `videos/cover-types`
  videoList$;
  @ViewChild('videoModal', { static: true }) videoModal!: TemplateRef<any>;

  paperOptions = [];

  subtitles = {
    'Color1Side1Page-Black1Side1Page': 'La primera cara de tu documento irá a color. El resto de tu documento irá en blanco y negro.',
    'Black1Side1Page-Black1Side1Page': 'Todo tu documento irá en blanco y negro.',
    'Color1Side1Page-Black1Side2Page': 'La primera cara de tu documento irá a color a 1 página por cara.  El resto de tu documento irá en blanco y negro a 2 páginas por cara.',
    'Black1Side1Page-Black1Side2Page': 'La primera cara de tu documento irá en blanco y negro a 1 página por cara. El resto de tu documento irá en blanco y negro a 2 páginas por cara.',
    'Black1Side2Page-Black1Side2Page': 'Todo tu documento irá en blanco y negro a 2 páginas por cara. ',
    'Color1Side1Page-Black1Side4Page': 'La primera cara de tu documento irá a color a 1 página por cara. El resto de tu documento irá en blanco y negro a 4 páginas por cara.',
    'Black1Side1Page-Black1Side4Page': 'La primera cara de tu documento irá en blanco y negro a 1 página por cara. El resto de tu documento irá en blanco y negro a 4 páginas por cara.',
    'Black1Side4Page-Black1Side4Page': 'Todo tu documento irá en blanco y negro a 4 páginas por cara. ',
    'Color2Side1Page-Black2Side1Page': 'La primera y segunda cara de tu documento irán a color. El resto de tu documento irá en blanco y negro a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Black2Side1Page': 'La primera cara de tu documento irá a color. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a doble cara (por delante y por detrás del folio).',
    'Black1Side1Page-Black2Side1Page': 'La primera cara de tu documento irá en blanco y negro. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a doble cara (por delante y por detrás del folio).',
    'Black2Side1Page-Black2Side1Page': 'Todo tu documento irá en blanco y negro, a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Black2Side2Page': 'La primera cara de tu documento irá a 1 página por cara, a color. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a 2 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Black1Side1Page-Black2Side2Page': 'La primera cara de tu documento irá a 1 página por cara en blanco y negro. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a 2 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Black2Side2Page-Black2Side2Page': 'Todo tu documento irá en blanco y negro, 2 páginas por cara, a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Black2Side4Page': 'La primera cara de tu documento irá a 1 página por cara a color. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a 4 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Black1Side1Page-Black2Side4Page': 'La primera cara de tu documento irá a 1 página por cara en blanco y negro. La segunda cara estará en blanco. El resto de tu documento irá en blanco y negro a 4 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Black2Side4Page-Black2Side4Page': 'Todo tu documento irá en blanco y negro, 4 páginas por cara, a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Color1Side1Page': 'Todo tu documento irá a color.',
    'Color1Side1Page-Color1Side2Page': 'La primera cara de tu documento irá a color a 1 página por cara. El resto de tu documento irá a color a 2 páginas por cara.',
    'Color1Side2Page-Color1Side2Page': 'Todo tu documento irá a color a 2 páginas por cara. ',
    'Color1Side1Page-Color1Side4Page': 'La primera cara de tu documento irá a color a 1 página por cara. El resto de tu documento irá a color a 4 páginas por cara.',
    'Color1Side4Page-Color1Side4Page': 'Todo tu documento irá a color a 4 páginas por cara. ',
    'Color2Side1Page-Color2Side1Page': 'Todo tu documento irá a color y a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Color2Side1Page': 'La primera cara de tu documento irá a color. La segunda cara estará en blanco.  El resto de tu documento irá a color y a doble cara (por delante y por detrás del folio).',
    'Color2Side2Page-Color2Side2Page': 'Todo tu documento irá a color a 2 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Color2Side2Page': 'La primera cara de tu documento irá a 1 página por cara a color. La segunda cara estará en blanco. El resto de tu documento irá a color a 2 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Color2Side4Page-Color2Side4Page': 'Todo tu documento irá a color a 4 páginas por cara y a doble cara (por delante y por detrás del folio).',
    'Color1Side1Page-Color2Side4Page': 'La primera cara de tu documento irá a 1 página por cara a color. La segunda cara estará en blanco.  El resto de tu documento irá a color a 4 páginas por cara y a doble cara (por delante y por detrás del folio).',
  }

  constructor(private appService: AppService, public modalController: ModalController, public fileService: FileService, private storage: AngularFireStorage) {
  }

  ngOnInit() {
    this.initPaperOptions()
    this.videoList$ = this.getVideoList()
    this.paperWeight = this.currentCoverType.paperWeight
    this.coverSettings = this.fileService.getCoverSettings();
    this.coverSettings = this.coverSettings.filter(setting => {
      return setting.requirements.some(requirements =>
        Object.keys(requirements).every(key =>
          this.fileSettings.hasOwnProperty(key) && this.fileSettings[key] === requirements[key]
        )
      );
    });
    if (this.fileSettings.coverLaminated) {
      this.coverSettings = this.coverSettings.filter(cs => cs.value.coverColor)
    }
    this.coverOption = this.currentCoverType
  }

  saveCoverType() {
    const coverType = { ...this.coverOption, paperWeight: this.paperWeight }
    if (this.fileService.getDefaultCoverType(this.fileSettings, this.fileSettings).id === this.coverOption.id) {
      coverType.default = true;
    } else {
      delete coverType.default
    }

    if (this.paperWeight !== this.currentCoverType.paperWeight) {
      coverType.paperWeightChanged = this.paperWeight
    }
    this.modalController.dismiss({ coverType });
  }

  getCoverTypeFullId(coverType) {
    return coverType.id + '-' +
      (this.fileSettings.docColor ? 'Color' : 'Black') +
      (this.fileSettings.twoSided ? '2' : '1') + 'Side' +
      this.fileSettings.pagesPerSheet + 'Page'
  }

  protected readonly PaperWeight = PaperWeight;

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  getVideoList(): Observable<string[]> {
    const ref = this.storage.ref(this.videoPath);
    return ref.listAll().pipe(
      map((result) => result.items.map((item) => item.name))
    );
  }

  protected readonly Finishing = Finishing;

  videoExists(videoList, coverType) {
    return false
    return videoList.includes(this.getCoverTypeFullId(coverType) + '.mp4')
  }

  async showVideo(event, coverType) {
    event.stopPropagation();

    const videoUrl = await this.storage.ref(`videos/cover-types/${this.getCoverTypeFullId(coverType) + '.mp4'}`).getDownloadURL().toPromise();

    const modal = await this.modalController.create({
      component: VideoPlayerModalComponent,
      cssClass: 'auto-height',
      componentProps: {
        videoUrl: videoUrl,
      }
    });

    await modal.present();
  }

  protected readonly Number = Number;
  protected readonly PageSize = PageSize;

  private initPaperOptions() {
    this.paperOptions = [
      {
        weight: PaperWeight.W80,
        title: '80 gr',
        subtitle: 'Un folio de toda la vida',
        condition: this.fileSettings.paperWeight === PaperWeight.W80
      },
      {
        weight: PaperWeight.W90,
        title: '90 gr',
        subtitle: 'Un poco más grueso',
        condition: Number(this.fileSettings.paperWeight) <= 90 && (this.fileSettings.finishing !== Finishing.STAPLED || this.fileSettings.paperWeight === PaperWeight.W90)
      },
      {
        weight: PaperWeight.W100,
        title: '100 gr',
        subtitle: 'Un poco más grueso aún',
        condition: Number(this.fileSettings.paperWeight) <= 100 && (this.fileSettings.finishing !== Finishing.STAPLED || this.fileSettings.paperWeight === PaperWeight.W100)
      },
      {
        weight: PaperWeight.W120,
        title: '120 gr',
        subtitle: 'Aún más grueso',
        condition: Number(this.fileSettings.paperWeight) <= 120 && this.fileSettings.pageSize !== PageSize.A3
          && (![Finishing.LAMINATED,Finishing.STAPLED].includes(this.fileSettings.finishing) || this.fileSettings.paperWeight === PaperWeight.W120)
      },
      {
        weight: PaperWeight.W160,
        title: '160 gr',
        subtitle: 'Ni fino ni grueso',
        condition: Number(this.fileSettings.paperWeight) <= 160 && (![Finishing.LAMINATED,Finishing.STAPLED].includes(this.fileSettings.finishing) || this.fileSettings.paperWeight === PaperWeight.W160)
      },
      {
        weight: PaperWeight.W200,
        title: '200 gr',
        subtitle: 'Tipo cartulina fina',
        condition: Number(this.fileSettings.paperWeight) <= 200 && this.fileSettings.pageSize === PageSize.A3
          && (![Finishing.LAMINATED,Finishing.STAPLED].includes(this.fileSettings.finishing) || this.fileSettings.paperWeight === PaperWeight.W200)
      },
      {
        weight: PaperWeight.W300,
        title: '300 gr',
        subtitle: 'Tipo cartulina del cole',
        condition: ![Finishing.LAMINATED,Finishing.STAPLED].includes(this.fileSettings.finishing)
      }
    ];
  }
}
